
export const SELECTED_SALLE = "SELECTED_SALLE"
export const SET_BOOKING_FORM_DATA = "SET_BOOKING_FORM_DATA"
export const BOOKING_ACTIVE_STEP = "BOOKING_ACTIVE_STEP"
export const BOOKING_FORM_VALIDATE = "BOOKING_FORM_VALIDATE"

export const GET_LOCATIONS = "GET_LOCATIONS"
export const GET_EVENT_TYPES = "GET_EVENT_TYPES"
export const GET_SALLES = "GET_SALLES"
export const GET_SERVICES = "GET_SERVICES"
export const RESERVE_SALLE = "RESERVE_SALLE"

export const CONTACT_ = "CONTACT_"
