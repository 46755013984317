import React from "react"
import styled from "styled-components"

interface TitleProprs {
    number?: number,
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 40px;
    height: 40px;
    position: absolute;
    top: -15px;
    right: 70px;
    background: #00B4FF;
    z-index: 2;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 20px 30px rgba(0, 128, 255, 0.35);
`;

const Badge = (props: TitleProprs) => {
    return (
        <Wrapper>
            <p className="badge-number">{props.number}</p>
        </Wrapper>
    )
}

export default Badge