import React from "react"
import styled from "styled-components"
import NextIcon from "./NextIcon";
import BackIcon from "./BackIcon";
import Check from "../../assets/white-check.svg"
import { useMediaQuery } from 'react-responsive'
interface NavProprs {
    title?: string,
    clickBack: () => void,
    clickNext: () => void,
    activeStep: number,
    nextButtonStatus: boolean
}

const TitleH1 = styled.h1`
    color: #0080FF;
    font-size: 30px;
    font-family: 'Poppins';
    font-weight: 300;
    text-align: center;
    position: relative;
    z-index: 1;
`;

const IconContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
`;

const Button = styled.button`
    display: flex;
    align-items: center;
    background: transparent;
    border-radius: 15px;
    border: 1px solid #102D47;
    width: 195px;
    height: 60px;
    p {
        text-align: center;
        font-size: 16px;
        width: 100%
    };
    cursor: pointer;
    position: relative
`;

const StepperNav = (props: NavProprs) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

    return (
        <Wrapper>
            <Button onClick={props.clickBack} style={{ width: isTabletOrMobile ? 55 : 195, transform: isTabletOrMobile ? 'scale(0.7)' : ''}}>
                <BackIcon iconColor="#102d47" />
                {isTabletOrMobile ? null : <p>Retour</p>}
            </Button>
            <TitleH1 
                style={{ 
                    fontSize: isTabletOrMobile ? 18 : 30,
                    width:  isTabletOrMobile ? 210 : ''
                }}
            >
                {props.title}
            </TitleH1>
            <Button 
                onClick={props.clickNext} 
                style={{ 
                    backgroundColor: props.nextButtonStatus ? '#00B4FF' : 'rgba(0, 0, 0, 0.1)',
                    border: 'none',
                    opacity: props.nextButtonStatus ? '1' : '0.38',
                    width: isTabletOrMobile ? 55 : 195,
                    transform: isTabletOrMobile ? 'scale(0.7)' : ''
                }}
            >
                
                {
                    isTabletOrMobile ? 
                        null 
                    :
                        <p style={{ color: props.nextButtonStatus ? 'white' : '#102D47' }}>
                            {props.activeStep < 2 ? 'Suivant' : 'Valider'}
                        </p>
                }
                {
                    props.activeStep < 2 ? 
                        <NextIcon iconColor={props.nextButtonStatus ? 'white' : '#102D47'} />
                    :
                        isTabletOrMobile ? 
                            <IconContainer>
                                <img src={Check} alt="check" style={{ width: 20, height: 20 }} />
                            </IconContainer>
                        :
                            null
                }
            </Button>
        </Wrapper>
    )
}

export default StepperNav