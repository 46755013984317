export const BASE_URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "https://izivent.shiftin-demo.com"
    : "https://izivent.shiftin-demo.com";

export const locations = BASE_URL + "/entities";

export const salles = BASE_URL + "/salles";

export const reservation = BASE_URL + "/reservations";

export const eventType = BASE_URL + "/events-types";

export const services = BASE_URL + "/services";

export const contact = BASE_URL + "/contact";
