import * as actionTypes from "./actionTypes"
import * as apis from "../utils/api"
import axios from "axios"

export function setSelectedSalle(salle: Object){
    const action: any = {
        type: actionTypes.SELECTED_SALLE,
        selectedSalle: salle,
    }
    return (dispatch: DispatchType) => {
          dispatch(action)
    }
}

export function setBookingFormData(data: Object){
    console.log({data})
    const action: any = {
        type: actionTypes.SET_BOOKING_FORM_DATA,
        bookingData: data,
    }
    return (dispatch: DispatchType) => {
          dispatch(action)
    }
}

export function setBookingActiveStep(step: number){
    const action: any = {
        type: actionTypes.BOOKING_ACTIVE_STEP,
        bookingActiveStep: step,
    }
    return (dispatch: DispatchType) => {
          dispatch(action)
    }
}

export function setBookingFormValidate(status: boolean){
    const action: any = {
        type: actionTypes.BOOKING_FORM_VALIDATE,
        formValidate: status,
    }
    return (dispatch: DispatchType) => {
          dispatch(action)
    }
}

export function clearState(){
    const actionFormValidate: any = {
        type: actionTypes.BOOKING_FORM_VALIDATE,
        formValidate: false,
    }
    const actionFormData: any = {
        type: actionTypes.SET_BOOKING_FORM_DATA,
        bookingData: {},
    }
    const actionSalle: any = {
        type: actionTypes.SELECTED_SALLE,
        selectedSalle: {},
    }
    const actionActiveStep: any = {
        type: actionTypes.BOOKING_ACTIVE_STEP,
        bookingActiveStep: 0,
    }
    const actionReservation: any = {
        type: actionTypes.RESERVE_SALLE,
        reservation: [],
        validatingReservation: false,
        reservationStatus: false
    }
    const actionContact: any = {
        type: actionTypes.CONTACT_,
        sendingMsg: false,
        sendingMsgStatus: ""
    }
    return (dispatch: DispatchType) => {
          dispatch(actionFormValidate)
          dispatch(actionFormData)
          dispatch(actionActiveStep)
          dispatch(actionSalle)
          dispatch(actionReservation)
          dispatch(actionContact)
    }
}

export function getLocatioins() {
    return function(dispatch) {
      return axios.get(apis.locations)
        .then(({ data }) => {
            console.log({data})
            let grouped = [
                {
                    label: 'Wilayas',
                    options: data.wilayas
                },
                {
                    label: 'Organismes',
                    options: data.organismes
                },
                {
                    label: 'Salles',
                    options: data.salles
                }
            ]
            dispatch({
                type: actionTypes.GET_LOCATIONS,
                locations: grouped
            })
      }).catch(error=>{
        console.log({error})
      })
    };
}

export function getEventTypes() {
    return function(dispatch) {
      return axios.get(apis.eventType)
        .then(({ data }) => {
            console.log({data})
            dispatch({
                type: actionTypes.GET_EVENT_TYPES,
                eventTypes: data
            })
      }).catch(error=>{
        console.log({error})
      })
    };
}

export function getSalles(filters, history) {

    console.log({filters})
    let query = ''
    if(history !== null){
        query += filters.group === 0 ? 'filter=wilayas.id||$eq||'+filters.location.id : (filters.group === 1 ? '?filter=organismes.id||$eq||'+filters.location.id : '?filter=salles.id||$eq||'+filters.location.id)
        query += '&filter=max_capacity||$eq||'+filters.people
        query += '&filter=events_types||$eq||'+filters.eventType
    }else{
        query += '&filter=events_types||$eq||'+filters.eventType
    }

    const uri = encodeURIComponent(query) 

    return function(dispatch, getState) {

        dispatch({
            type: actionTypes.GET_SALLES,
            salles: [],
            sallesLoading: true,
            sallesStatus: false
        })

    return fetch(apis.salles+'?%27'+uri)
        .then((response) => response.json())
        .then((data) => {
            console.log('all salles', {data})

            dispatch({
                type: actionTypes.GET_SALLES,
                salles: data,
                sallesLoading: false,
                sallesStatus: true
            })
            if(history !== null){
                history.push({
                    pathname: "/booking", 
                })
            }
        })
        .catch((error) => {
            console.log({error})
            dispatch({
                type: actionTypes.GET_SALLES,
                salles: [],
                sallesLoading: false,
                sallesStatus: false
            })
        });
    };
}

export function getServices() {

    return function(dispatch) {

      return axios.get(apis.services)
        .then(({ data }) => {
            console.log({data})
            dispatch({
                type: actionTypes.GET_SERVICES,
                services: data,
            })
      }).catch(error=>{
        console.log({error})
        dispatch({
            type: actionTypes.GET_SERVICES,
            services: [],
        })

      })
    };
}

export function reserveSalle(data) {


    console.log('{data}')
    console.log({data})

    let services = []

    data.addons.map((val)=>{
        return services.push((val.id).toString())
    })

    let body = {
        "salleId": data.salle.id,
        "nbr_personnes": data.peopleNbr,
        "eventsTypesId": data.type.id,
        "date": data.date,
        "time": data.time,
        "duration": data.duration,
        "client_type": data.organization,
        "name": data.fullname,
        "email": data.email,
        "phone": data.phone,
        "organisme_name": data.organizationName,
        "fonction": data.job,
        "services": services,
        "description": data.message
      }

      console.log({body})

    return function(dispatch) {

        dispatch({
            type: actionTypes.RESERVE_SALLE,
            reservation: [],
            validatingReservation: true,
            reservationStatus: false
        })

      return axios.post(apis.reservation, body)
        .then(({ data }) => {
            console.log('resesrvation response')
            console.log({data})
            console.log(axios)
            dispatch({
                type: actionTypes.RESERVE_SALLE,
                reservation: [],
                validatingReservation: false,
                reservationStatus: true
            })

      }).catch(error=>{
        console.log({error})
        dispatch({
            type: actionTypes.RESERVE_SALLE,
            reservation: [],
            validatingReservation: false,
            reservationStatus: false
        })
      })
    };
}

export function sendMessage(name, email, message) {

    let body = {
        "name": name,
        "email": email,
        "message": message
    }

    console.log({body})

    return function(dispatch) {

        dispatch({
            type: actionTypes.CONTACT_,
            sendingMsg: true,
            sendingMsgStatus: "",
        })

      return axios.post(apis.contact, body)
        .then(({ data }) => {
            console.log({data})
            setTimeout(() => {
                dispatch({
                    type: actionTypes.CONTACT_,
                    sendingMsg: false,
                    sendingMsgStatus: "SUCCESS",
                })
                setTimeout(() => {
                    dispatch({
                        type: actionTypes.CONTACT_,
                        sendingMsg: false,
                        sendingMsgStatus: "",
                    })
                }, 2000);
            }, 1000);

      }).catch(error=>{
        console.log({error})
        dispatch({
            type: actionTypes.CONTACT_,
            sendingMsg: false,
            sendingMsgStatus: "FAIL",
        })
      })
    };
}