import * as React from "react"

function NextIcon(props: any) {

  return (
      <div className="next">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 16 16.828"
            >
                <g
                    fill="none"
                    stroke={props.iconColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                >
                    <path data-name="Line 10" d="M1 8.414h14" />
                    <path data-name="Path 42" d="M8 15.414l7-7-7-7" />
                </g>
            </svg>
        </div>
  )
}

export default NextIcon
