import React from "react"
import styled from "styled-components"
import ScrollAnimation from 'react-animate-on-scroll';
import { useMediaQuery } from "react-responsive";

interface TitleProprs {
    title?: string,
    description?: string,
    icon?: string,
    index: number,
}

const Card = styled.div`
    width: 175px;
    height: 175px;
    background: #F2F6FA;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 20px 30px #d2dde8;
    margin-bottom: 30px
`;

const Title = styled.h2`
    font-size: 25px;
    color: #102D47;
    font-weight: 400
`;

const Description = styled.p`
    font-size: 16px;
    color: #102D47;
    font-weight: 300;
    margin-top: 10px;
    line-height: 22px
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    position: relative;
    z-index: 1
`;

const WhyIziCard = (props: TitleProprs) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })
    
    return (
        <Wrapper 
            style={{ 
                alignItems: isTabletOrMobile ? 'center' : '',
                marginBottom: isTabletOrMobile ? '50px' : '',
            }}
        >
            <ScrollAnimation animateIn="zoomIn" delay={props.index * 100} duration={0.5}>
                <Card>
                    <ScrollAnimation animateIn="rotateIn" delay={props.index * 150} duration={0.7}>
                        <img src={props.icon} alt="icon"/>
                    </ScrollAnimation>
                </Card>
            </ScrollAnimation>
            <ScrollAnimation animateIn="slideInDown" delay={props.index * 150}>
                <Title>
                    {props.title}
                </Title>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn" delay={props.index * 150}>
                <Description>
                    {props.description}
                </Description>
            </ScrollAnimation>
            
        </Wrapper>
    )
}

export default WhyIziCard