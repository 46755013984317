import React from "react"
import "./styles.css"
import HookForm from "./HookForm"
import { useMediaQuery } from 'react-responsive'
interface BookingProps {
    clickNext: () => void
}
const App = (props: BookingProps) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

    return (
        <div 
            className="booking-form" 
            style={{ 
                padding: isTabletOrMobile ? '20px 10px' : '30px 60px',

            }}
        >
            <HookForm clickNext={props.clickNext} isTabletOrMobile={isTabletOrMobile} />
        </div>
    )

}

export default App