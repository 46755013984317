import React, { useCallback, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import Title from "../Title"
import Reference from "./Reference"
import { references } from "../../utils/constants"
import PreviousIcon from "./PreviousIcon"
import NextIcon from "./NextIcon"
import 'swiper/swiper-bundle.min.css'
import { useMediaQuery } from "react-responsive"

export default function SwiperCom() {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

  const swiperRef = useRef(null);
  SwiperCore.use([Autoplay]); 

  const prevSlide = useCallback(() => {
    swiperRef.current?.swiper.slidePrev();
  }, [swiperRef]);

  const nextSlide = useCallback(() => {
    swiperRef.current?.swiper.slideNext();
  }, [swiperRef]);

  return (
    <div className="referencesBloc" id="references" style={{padding: isTabletOrMobile ? '50px 0' : ''}}>

        <Title title="Nos établissements partenaires" marginBottom="20px" width={isTabletOrMobile ? '100%' : ''} fontSize={isTabletOrMobile ? '24px' : ''} />
        <p className="subtitle " style={{padding: isTabletOrMobile ? '0 20px' : '', textAlign: isTabletOrMobile ? 'center' : 'left'}}>
            Nous sommes fiers de vous proposer les meilleurs établissements en mesure d'accueillir vos événements
        </p>

        <div className="carousel-references" style={{ width: isTabletOrMobile ? '100%' : '85%' }}>

            <PreviousIcon click={prevSlide} />

            <Swiper
                ref={swiperRef}
                spaceBetween={isTabletOrMobile ? 50 : 100}
                slidesPerView={isTabletOrMobile ? 1 : 4}
                autoplay={{ delay: 1000 }}
                loop
            >
                {
                    references.map((val, ind)=>{
                        return(
                            <SwiperSlide key={ind}>
                                <Reference inactiveIcon={val.inactiveIcon} />
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            
            <NextIcon click={nextSlide} />

        </div>
    </div>
  );
}