import React, { FunctionComponent } from "react"
import "./styles.css"
import Footer from "../../components/Footer"
import BookingHeader from "../../components/Header/BookingHeader"
import FilteredRoomsList from "../../components/FilteredRoomsList"
import "./styles.css";
import RiseLoader from "react-spinners/ClipLoader";

const App: FunctionComponent = () => {

  let [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [])

  return (
    <>
        <BookingHeader/>
        {
          loading ?
            <div style={{ minHeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <RiseLoader color={"#00B4FF"} loading={loading} size={30} />
            </div>
          :
            <FilteredRoomsList />
        }
        <Footer/>
    </>
  )
}

export default App
