import Img1 from "../assets/img1.jpg"
import Img2 from "../assets/img2.jpg"
import Img3 from "../assets/img3.jpg"
import Img4 from "../assets/img4.jpg"

import logo1 from "../assets/logos-partners/Standall-Alger.png"
import logo2 from "../assets/logos-partners/Beldi_Park-Alger.png"
import logo3 from "../assets/logos-partners/CIC-Alger.png"
import logo4 from "../assets/logos-partners/El_Aurassi-Alger.png"
import logo5 from "../assets/logos-partners/Golden_Tulip-Alger.png"
import logo6 from "../assets/logos-partners/Holiday_Inn-Alger.png"
import logo7 from "../assets/logos-partners/Hyatt-Regency-Alger.png"
import logo9 from "../assets/logos-partners/Mariott-Alger.png"
import logo10 from "../assets/logos-partners/Radisson-Alger.png"
import logo11 from "../assets/logos-partners/Sheraton-Alger.png"
import logo12 from "../assets/logos-partners/Sofitel-Alger.png"

export const REACT_APP_SITE_KEY = "6LeDO7UcAAAAAM68K4biIj6qOyW7hP_sMgseQUF4"

export const roomTypes = [
    { value: 'meeting', label: 'Salle de réunions' },
    { value: 'classroom', label: 'Salles de formation' },
    { value: 'conference', label: 'Salles de conférences' }
]

export const locations = [
    { value: 'alger', label: 'Alger' },
    { value: 'boumerdes', label: 'Boumerdes' },
    { value: 'bouira', label: 'Bouira' },
]

export const MONTHS = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
export const WEEKDAYS_LONG = [
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
  ];
export const WEEKDAYS_SHORT = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];

export const addons = [
    {label: 'Bouteilles d’eau', value: 'water'},
    {label: 'Forfait café', value: 'coffee'},
    {label: 'Projecteur Data Show', value: 'datashow'}
]

export const roomsList = [
    {
        uid: 'd85863f8-0a61-11ec-9a03-0242ac130003',
        name: "El Mawaqif",
        building: "El Aurassi Hotel",
        price: '20000',
        location: 'Alger',
        image: Img1
    },
    {
        uid: 'f170823a-0a61-11ec-9a03-0242ac130003',
        name: "El Mawaqifs",
        building: "El Aurassi Hotel",
        price: '20000',
        location: 'Alger',
        image: Img2
    },
    {
        uid: 'f5d556c0-0a61-11ec-9a03-0242ac130003',
        name: "El Mawaqif",
        building: "Hotel Sheraton",
        price: '20000',
        location: 'Alger',
        image: Img3
    },
    {
        uid: 'fbadce42-0a61-11ec-9a03-0242ac130003',
        name: "El Mawaqif",
        building: "El Aurassi Hotel",
        price: '20000',
        location: 'Alger',
        image: Img4
    },
    {
        uid: '01646daa-0a62-11ec-9a03-0242ac130003',
        name: "El Mawaqif",
        building: "El Aurassi Hotel",
        price: '20000',
        location: 'Alger',
        image: Img4
    },
    {
        uid: '05ece3fc-0a62-11ec-9a03-0242ac130003',
        name: "El Mawaqif",
        building: "El Aurassi Hotel",
        price: '20000',
        location: 'Alger',
        image: Img4
    },
]

export const references = [
    {
        inactiveIcon: logo1
    },
    {
        inactiveIcon: logo2
    },
    {
        inactiveIcon: logo3
    },
    {
        inactiveIcon: logo4
    },
    {
        inactiveIcon: logo5
    },
    {
        inactiveIcon: logo6
    },
    {
        inactiveIcon: logo7
    },
    {
        inactiveIcon: logo9
    },
    {
        inactiveIcon: logo10
    },
    {
        inactiveIcon: logo11
    },
    {
        inactiveIcon: logo12
    }
]