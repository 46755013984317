import React from 'react'
import styled from "styled-components"

interface InputProprs {
    inactiveIcon?: string,
}

const Container = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center
`;

const Image = styled.img`
//   width: 140px;
`;

const Reference = (props: InputProprs) => {
    return(
            <Container>
                <Image src={props.inactiveIcon} alt="reference" />
            </Container>
    )

}

export default Reference
