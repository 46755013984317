import React from "react"
import styled from "styled-components"
import Badge from "./Badge"
import "./styles.css"
import ScrollAnimation from 'react-animate-on-scroll';
import Arrow from "../../assets/arrow-right.svg"
import { useMediaQuery } from "react-responsive";

interface TitleProprs {
    description?: string,
    icon?: string,
    number: number
}

const Card = styled.div`
    width: 125px;
    height: 125px;
    background: #F2F6FA;
    border-radius: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 20px 30px #D2DDE8;
    margin-bottom: 20px
`;

const Description = styled.p`
    font-size: 16px;
    color: #102D47;
    font-weight: 300;
    margin-top: 10px;
    line-height: 22px
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    position: relative;
    z-index: 0;
    position: relative;
    padding-top: 20px
`;

const HowItWorksCard = (props: TitleProprs) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })
    
    return (
        <Wrapper style={{ marginBottom: isTabletOrMobile ? 30 : 0 }}>
            <img src={Arrow} alt="arrow" className="arrow" />
            <ScrollAnimation animateIn="bounceIn" delay={props.number * 150} duration={0.7} className="badge">
                <Badge number={props.number} />
            </ScrollAnimation>
            
            <ScrollAnimation animateIn="zoomIn" delay={props.number * 100} duration={0.7}>
                <Card>
                    <img src={props.icon} alt="icon"/>
                </Card>    
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn" delay={props.number * 150} duration={0.8}>
                <Description>
                    {props.description}
                </Description>
            </ScrollAnimation>
        </Wrapper>
    )
}

export default HowItWorksCard