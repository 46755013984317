import React from "react"
import { FilterInput } from "../FilterInput"
import MapPin from "../../assets/map-pin.svg"
import Calendar from "../../assets/calendar.svg"
import Chevrons from "../../assets/chevrons-down.svg"
import User from "../../assets/user.svg"
import SearchButton from "./SearchButton"
import { Separator } from "./Separator"
import "./styles.css"
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import moment from "moment"
import Select, { components } from 'react-select'
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from "../../utils/constants"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setSelectedSalle, getSalles } from "../../store/actionCreators"
import { useMediaQuery } from "react-responsive"

  const customStyles = {
    control: (styles: any) => ({ ...styles, 
        fontFamily: 'Poppins',
        fontSize: 14,
        border: 'none',
        borderRadius: 10,
        boxShadow: 'none',
        // height: 45,
        width: 200,
        marginLeft: -15
    }),
    option: (styles: any, data: any) => {
        return {
        ...styles,
        backgroundColor: data.isSelected ? '#00B4FF' : 'white',
        ':active': {
          ...styles[':active'],
        },
      };
    },
    placeholder: (styles: any) => ({ 
        opacity: 0.6
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    dropdownIndicator: () => ({
        display: 'none'
    }),
    noOptionsMessage: (base: any) => ({ 
        ...base,         
        fontSize: 12
    })
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  
  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={{
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
        }}>
          {data.options.length}
        </span>
    </div>
  );

const App: React.FC = () => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

    const history = useHistory();
    const dispatch = useDispatch()
    const locations = useSelector((state: any) => state.locations)
    const eventTypes = useSelector((state: any) => state.eventTypes)
    const loading = useSelector((state: any) => state.sallesLoading)
    const [selectedType, setSelectedType] = React.useState();
    const [selectedLocation, setSelectedLocation] = React.useState();
    const [selectedGroup, setSelectedGroup] = React.useState(0);
    const [peopleNumber, setPeopleNumber] = React.useState(0);
    const [date, onChange] = React.useState();
    const [showDayPicker, onShowDayPicker] = React.useState(false);
    const [error, setError] = React.useState({
        status: false,
        message: ''
    });

    const today = new Date();

    const handleClickDay = (date: any, modifiers) => {
        if (modifiers.disabled) {
            return;
        }
        onChange(date)
        onShowDayPicker(false)
    }
    const modifiers = {
        selected: date,
    };

    React.useEffect(()=>{
        setError({
            status: false,
            message: ''
        })
    }, [date, peopleNumber, selectedType, selectedLocation])

    const handleClickSearch = () => {

        let data = {
            people: peopleNumber,
            date: date,
            location: selectedLocation,
            eventType: selectedType,
            group: selectedGroup
        }
        
        if(!!date && !!selectedLocation && !!selectedType && peopleNumber > 0){
            dispatch(setSelectedSalle(data))
            dispatch(getSalles(data, history))
        }else{
            setError({
                status: true,
                message: 'Veuillez compléter tous les champs.'
            })
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        var num: number = +event.target.value;
        setPeopleNumber(num)
    }

    const NoOptionsMessage = (props: any) => {
        return (
          <components.NoOptionsMessage {...props}>
            <span> Lieu inexistant, veuillez choisir un lieu à partir de la liste. </span> 
          </components.NoOptionsMessage>
        );
      };

    return (
        <>
        <div className="filters">
            <div style={{ display: 'flex', alignItems: 'center', borderTop: 0, width: isTabletOrMobile ? '100%' : '' }}>
                <FilterInput icon={MapPin} placeholder="Où ?" hideInput={true} />
                <Select
                    // value={locations[selectedGroup].options.find((c) => c.name === selectedLocation?.name)}
                    formatGroupLabel={formatGroupLabel}
                    styles={customStyles} 
                    placeholder="Où ?"
                    options={locations}
                    getOptionValue={option => option}
                    getOptionLabel={option => option.name}
                    components={{ NoOptionsMessage }}
                    onChange={(selectedLocation: any) => {
                        if(!!selectedLocation.code){
                            setSelectedGroup(0)
                        }else if(!!selectedLocation.price){
                            setSelectedGroup(2)
                        }else{
                            setSelectedGroup(1)
                        }
                        setSelectedLocation(selectedLocation);
                    }}
                />
            </div>
            {!isTabletOrMobile && <Separator />}

            <div className='inpt' 
                style={{    
                    display: 'flex', alignItems: 'center', 
                    borderTop: isTabletOrMobile ? '0.3px solid lightgrey' : 0, 
                    width: isTabletOrMobile ? '100%' : ''
                }}>
                <FilterInput 
                    icon={User} 
                    placeholder="Nbr de personnes" 
                    inputType={'number'} 
                    textChange={handleInputChange} 
                    value={peopleNumber > 0 ? peopleNumber.toString() : ''}
                />
            </div>

            {!isTabletOrMobile && <Separator />}

            <div 
                style={{ 
                    display: 'flex', alignItems: 'center',
                    borderTop: isTabletOrMobile ? '0.3px solid lightgrey' : 0, 
                    width: isTabletOrMobile ? '100%' : ''
                }}
            >
                <FilterInput icon={Chevrons} placeholder="Type d’événement" hideInput={true} />
                <Select
                    value={Array.isArray(eventTypes) ? eventTypes?.find(({id}) => id === selectedType) : undefined}
                    styles={customStyles} 
                    placeholder="Type d’événement"
                    options={eventTypes}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.name}
                    // components={{ DropdownIndicator }}
                    isSearchable={false}
                    onChange={(selectedType: any) => {
                        setSelectedType(selectedType.id);
                    }}
                />
            </div>
            
            {!isTabletOrMobile && <Separator />}

            <div 
                style={{ 
                    position: 'relative',
                    borderTop: isTabletOrMobile ? '0.3px solid lightgrey' : 0, 
                    width: isTabletOrMobile ? '100%' : '',
                    height: 'auto'
                }}
            >
                <FilterInput 
                    value={!!date ? moment(date).format('DD/MM/YYYY') : ''} 
                    icon={Calendar} placeholder="Quand ?" 
                    click={()=>{ onShowDayPicker(true) }}
                    textChange={()=>{}}
                />
                {
                    showDayPicker &&
                    <DayPicker
                        locale="fr"
                        months={MONTHS}
                        weekdaysLong={WEEKDAYS_LONG}
                        weekdaysShort={WEEKDAYS_SHORT}
                        firstDayOfWeek={1}
                        onDayClick={(date, modifiers)=>handleClickDay(date, modifiers)}
                        modifiers={modifiers}
                        disabledDays={{ before: today }}
                    />
                }
            </div>

            <SearchButton loading={loading} clickSearch={handleClickSearch} />
        </div>
        {error.status && <p className="error-msg"> {error.message} </p>}
        </>
    )
}

export default App