
import React, { FunctionComponent } from "react"
import "./styles.css"
import ListItem from "./ListItem"
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedSalle } from "../../store/actionCreators"
import { BASE_URL } from "../../utils/api"
import { useMediaQuery } from "react-responsive"

const App: FunctionComponent = () => {

    const roomData = useSelector((state: any) => state.selectedSalle)
    const salles = useSelector((state: any) => state.salles)
    const [selectedItem, setSelectedItem] = React.useState<Object>(roomData)
    const dispatch = useDispatch()

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

    const handleSelect = (ind: number, item: Object) => {
        setSelectedItem(item)
        dispatch(setSelectedSalle({...item, ...roomData}))
    }

  return (
    <div className="salles-list" style={{ justifyContent: isTabletOrMobile ? 'center' : '' }}>
        {
            salles.map((val, ind)=> {

                const imageLink = BASE_URL+'/api/uploads/'+val.filePath

                return(
                    <div key={ind} onClick={()=>handleSelect(ind, val)} style={{ cursor: 'pointer' }}>
                        <ListItem 
                            name={val.name}
                            building={val.organismes[0].name}
                            price={val.price}
                            location={val.wilayas[0].name}
                            capacity={val.max_capacity}
                            image={imageLink}
                            clickSelect={()=>{}}
                            selectedItem={selectedItem}
                            item={val.id}
                        />
                    </div>
                )
            })
        }
    </div>
  )
}

export default App