import React from "react"
import "./styles.css"
import styled from "styled-components"
import { useHistory } from "react-router-dom"

interface CardProprs {
    background?: string,
    title: string,
    salles?: string,
    id?: number,
    width?: string
}

const Card = styled.div`
    background-image:
    linear-gradient(to bottom, rgba(16, 45, 71, 0), rgba(16, 45, 71, 0.6)),
    url('${(props: CardProprs) => props.background}');
    width: ${(props: CardProprs) => props.width ? props.width : '350px'};
    height: 230px;
    border-radius: 15px;
    padding: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: 15px;
    cursor: pointer
`;

function App (props: CardProprs) {

    const history = useHistory()

    const clickItem = () => {
        console.log(props)
        history.push({
            pathname: "/salles/"+(props.title).replace(/\s+/g, '-').toLowerCase(), 
            state: {
                id: props.id,
                title: props.title
            }
        })
    }

  return (
        <Card onClick={clickItem} title={props.title} background={props.background} width={props.width}>

            <div className="text-box">
                <h4>
                    {props.title}
                </h4>
                <h6>
                    {
                        parseInt(props.salles) > 1 ?
                            props.salles+' Salles'
                        :
                            props.salles+' Salle'
                    }
                </h6>
            </div>

        </Card>
  )
}

export default App
