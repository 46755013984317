import React, { FunctionComponent } from "react"
import "./styles.css"
import SallesCard from "../SallesCard"
import Ribbon3 from "../../assets/ribbon3.svg"
import Ribbon4 from "../../assets/ribbon4.svg"
import Title from "../Title"
import ScrollAnimation from 'react-animate-on-scroll';
import { useSelector } from "react-redux"
import styled from "styled-components"
import { useHistory } from "react-router"
import { BASE_URL } from "../../utils/api"
import { useMediaQuery } from "react-responsive"

const Button = styled.button`
    background: #00B4FF;
    color: white;
    font-size: 14px;
    border: none;
    border-radius: 10px;
    width: 245px;
    height: 70px;
    font-family: 'Poppins';
    margin-right: 17px;
    display: block;
    align-self: flex-end;
    margin-top: 10px;
    cursor: pointer
`;

const App: FunctionComponent = () => {

    const sallesData = useSelector((state: any) => state.eventTypes)
    const history = useHistory()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

    const handleClick = (title) => {

        history.push({
            pathname: "/salles/"+(title).replace(/\s+/g, '-').toLowerCase(), 
            state: {
                id: null,
                title: title
            }
        })

    }

  return (
    <div className="sallesBloc" id="salles" style={{padding: isTabletOrMobile ? '50px 0' : ''}}>
        <img src={Ribbon3} alt="ribbon" className="ribbon3" />
        <img src={Ribbon4} alt="ribbon" className="ribbon4" />

        <Title 
            title="Des lieux adaptés pour chaque besoin au meilleur tarif" 
            fontSize={isTabletOrMobile ? '24px' : ''} 
            width={isTabletOrMobile ? '100%': ''} 
            marginBottom={isTabletOrMobile ? '50px': ''} 
        />

        <div className="list-salles">
            {
                Array.isArray(sallesData) ?
                    sallesData?.map((val, ind)=>{

                        const imageLink = BASE_URL+'/api/uploads/'+val.filePath

                        return(
                            isTabletOrMobile ? 
                                <SallesCard 
                                    title={val.name} 
                                    salles={val.salles.length} 
                                    background={imageLink} id={val.id} 
                                    width={isTabletOrMobile ? '270px' : null}
                                />
                            :
                                <ScrollAnimation animateIn="fadeIn" delay={ind*100} key={ind}>
                                    <SallesCard 
                                        title={val.name} 
                                        salles={val.salles.length} 
                                        background={imageLink} id={val.id} 
                                        width={isTabletOrMobile ? '270px' : null}
                                    />
                                </ScrollAnimation>
                        )
                    })
                :
                    null
            }

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20, zIndex: 2 }}>
                <Button
                    onClick={()=>{
                        handleClick('Toutes les salles')
                    }}
                    style={{ marginRight: isTabletOrMobile ? 0 : ''}}
                > 
                    Découvrir toutes les salles
                </Button>
            </div>
        </div>
        
    </div>
  )
}

export default App