import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import styled from "styled-components"
import Arrow from "../../assets/arrow-right.svg"
import Check from "../../assets/check.svg"
import "./styles.css"
import { StepIconProps } from '@material-ui/core/StepIcon';
import clsx from 'clsx';
import StepperNav from "./StepperNav"
import { useHistory } from "react-router-dom";
import SallesList from "../SallesList"
import BookingForm from "../BookingForm"
import Recap from "../Recap"
import DoneBooking from "../DoneBooking"
import { useSelector, useDispatch } from "react-redux"
import { clearState, setBookingActiveStep, reserveSalle } from "../../store/actionCreators"
import { useMediaQuery } from 'react-responsive';

const StyledStepLabel = styled(StepLabel)`
    .MuiStepLabel-label {
        color: #102D47;
        font-size: 16px;
        font-family: 'Poppins';
        font-weight: 200;
        opacity: 0.6;
        margin-left: 10px;
    }
    .MuiStepLabel-active {
        font-weight: 300;
        opacity: 1;
    }
`;

const StyledStep = styled(Step)`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justity-content: center
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '85%',
      margin: '0 auto',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

const useStepIconStyles = makeStyles({
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
        backgroundColor: '#00B4FF',
    },
    inactive: {
        backgroundColor: 'rgba(16, 45, 71, 0.3)',
    },
    square: {
        width: 35,
        height: 35,
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      
    },
    completed: {
        backgroundColor: '#23E399',
    },
    stepNum: {
        color: 'white',
        fontSize: 19
    }
});

function StepIcon(props: StepIconProps, index: number) {
    const classes = useStepIconStyles();
    const { active, completed } = props;
    return (
      <div
        className={clsx(classes.root)}
        style={{
            boxShadow: completed ? '0 20px 30px #23E399' : (active ? '0 20px 30px rgba(0, 128, 255, 0.35)' : 'none'),
        }}
      >
        {
            completed ? 
                <div className={clsx(classes.square, classes.completed)} >
                    <img src={Check} alt="check" />
                </div> 
            : 
                <div className={clsx(classes.square, active ? classes.active : classes.inactive)} >
                    <p className={classes.stepNum}>
                        {index}
                    </p>
                </div>
        }
      </div>
    );
}

export default function HorizontalLinearStepper() {
  const classes = useStyles();
  const [nextButtonStatus, setNextButtonStatus] = React.useState(false);
  const steps = getSteps();
  let history = useHistory();
  const dispatch = useDispatch()
  const selected = useSelector((state: any) => state.selectedSalle)
  const formValidate = useSelector((state: any) => state.formValidate)
  const bookingActiveStep = useSelector((state: any) => state.bookingActiveStep)
  const bookingData = useSelector((state: any) => state.bookingData)
  const reservationStatus = useSelector((state: any) => state.reservationStatus)
  const [activeStep, setActiveStep] = React.useState(bookingActiveStep);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

  function getSteps() {
    return ['Sélection de salle', 'Formulaire de réservation', 'Confirmation'];
  }
  
  function getStepsTitle() {
      return ['Veuillez sélectionner une salle', 'Veuillez remplir le formulaire suivant', 'Confirmez votre demande'];
  }
  
  function getStepContent(step: number) {
    switch (step) {
      case 0:
          return (
              <>
                <SallesList />
              </>
          );
      case 1:
          return (
            <>
              <BookingForm clickNext={()=>handleNext()} />
            </>
          );
      case 2:
          return (
            <>
              <Recap clickValid={()=>handleNext()} />
            </>
          );
      default:
        return 'Unknown step';
    }
  }

  React.useEffect(() => {
    if(!!selected){
      setNextButtonStatus(!!selected.name ? true : false)
    }
  }, [selected])

  React.useEffect(() => {
    if(reservationStatus){
      dispatch(clearState())
      setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
      dispatch(setBookingActiveStep(activeStep+1))
    }
  }, [reservationStatus, dispatch, activeStep])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const handleNext = () => {
    bookingData.salle = selected

      if(activeStep === 2){
        dispatch(reserveSalle(bookingData))
      }
        
      if(activeStep === 1){
        if(!!document && document !== null){
          if(formValidate){
            setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
            dispatch(setBookingActiveStep(activeStep+1))
            document.getElementById('submitForm')?.click()
          }else{
            document.getElementById('submitForm')?.click()
          }
        }
      }

      if(activeStep === 0){
        if(nextButtonStatus){
          setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
          dispatch(setBookingActiveStep(activeStep+1))
        }
      }

      scrollToTop()
  };

  const handleBack = () => {
      if(activeStep === 0){
        history.goBack()
      }else{
        setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
        dispatch(setBookingActiveStep(activeStep-1))
      }
  };

  return (
    <div>
      <Stepper 
        activeStep={activeStep}
        connector={<img src={Arrow} alt="arrow" className="arrow-connector" />}
        style={{ 
          // overflow: isTabletOrMobile ? 'scroll' : 'hidden',
          justifyContent: 'center',
          padding: isTabletOrMobile ? 20 : 24
        }}
      >
        {steps.map((label, index) => {
          const stepNumber = index + 1
          return (
              <StyledStep 
                key={label} 
                style={{

                }}
              >
                  <StyledStepLabel 
                      StepIconComponent={(StepIconProps: StepIconProps)=>StepIcon(StepIconProps, stepNumber)} 
                  >
                          {
                            isTabletOrMobile ?
                              ''
                            :
                              label
                          }
                  </StyledStepLabel>
              </StyledStep>
          );
        })}
      </Stepper>
      
      {
        isTabletOrMobile ?
          <h3 style={{ textAlign: 'center', padding: 10, background: 'white', color: '#00B4FF' }}>
            {steps[activeStep]}
          </h3>
        :
          null
      }
      
      <div className={classes.root} style={{ width: isTabletOrMobile ? '100%' : '85%' }}>
        {activeStep === steps.length ? (

            <DoneBooking />          

        ) : (
          <div>
                <StepperNav 
                    clickNext={handleNext} clickBack={handleBack} 
                    title={getStepsTitle()[activeStep]} activeStep={activeStep} 
                    nextButtonStatus={nextButtonStatus}
                />
            
                {getStepContent(activeStep)}
          </div>
        )}
      </div>
    </div>
  );
}