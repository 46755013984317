import * as React from "react"

function PreviousIcon(props: any) {

    const [iconColor, setIconColor] = React.useState('rgba(16, 45, 71, 0.32)')

  return (
    <div 
        className="nav-icon"
        onMouseEnter={() => { setIconColor('#0080FF') }} 
        onMouseLeave={() => { setIconColor('rgba(16, 45, 71, 0.32)') }}
        onClick={props.click}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 22 22"
      >
        <g
          transform="translate(1 1)"
          fill="none"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <circle data-name="Ellipse 8" cx={10} cy={10} r={10} />
          <path data-name="Path 87" d="M10 14l-4-4 4-4" />
          <path data-name="Line 17" d="M14 10H6" />
        </g>
      </svg>
    </div>
  )
}

export default PreviousIcon
