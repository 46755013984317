import React from "react"
import "./styles.css"
import { useSelector } from "react-redux"
import Title from "./Title"
import Separator from "./Separator"
import styled from "styled-components"
import moment from "moment"
import Checked from "../../assets/check-active.svg"
import { useMediaQuery } from 'react-responsive'
import RiseLoader from "react-spinners/ClipLoader"
interface RecapProps {
    clickValid: () => void
}

const H3 = styled.h3`
    color: #102D47;
    font-size: 26px;
    font-family: 'Poppins';
    font-weight: 400;
    text-align: left;
    position: relative;
    z-index: 1;
    line-height: 34px
`;

const Label = styled.p`
    color: #102D47;
    opacity: 0.7;
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 300;
    text-align: left;
    position: relative;
    z-index: 1;
    line-height: 34px;
    margin-right: 10px
`;

const Value = styled.p`
    color: #102D47;
    font-size: 16px;
    font-family: 'Poppins';
    text-align: left;
    position: relative;
    z-index: 1;
    line-height: 34px
`;

const Button = styled.button`
    background: #00B4FF;
    color: white;
    font-size: 14px;
    border: none;
    border-radius: 15px;
    width: 245px;
    height: 70px;
    font-family: 'Poppins';
    cursor: pointer;
    // float: right
`;

const App = (props: RecapProps) => {

    const bookingData = useSelector((state: any) => state.bookingData)
    const roomData = useSelector((state: any) => state.selectedSalle)
    const validatingReservation = useSelector((state: any) => state.validatingReservation)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })
    console.log({roomData})
    return (
        <div className="recap-form" style={{ padding: isTabletOrMobile ? '20px 10px' : '30px 60px' }}>
            <Title fontSize={isTabletOrMobile ? '18px' : '24px'} title="Informations sur l’événement" />
            <div className="row">
                <div>
                    <H3 style={{ fontSize: isTabletOrMobile ? '20px' : '26px' }}>{roomData?.name}</H3>
                    <Label>{roomData?.organismes.length ? roomData?.organismes[0]?.name : ''} - {roomData?.location?.name}</Label>
                    <Value>{moment(bookingData?.date).format("DD/MM/YYYY")} {bookingData?.time !== '' ? 'à '+bookingData.time : ''}</Value>
                </div>
                <div>
                    <div className="row-small">
                        <Label>Type d’événement:</Label>
                        <Value>{bookingData?.type?.name}</Value>
                    </div>
                    <div className="row-small">
                        <Label>Durée:</Label>
                        <Value>{bookingData?.duration} {bookingData?.duration > 1 ? "heures" : "heure"}</Value>
                    </div>
                    <div className="row-small">
                        <Label>Nombre de personnes:</Label>
                        <Value>{bookingData?.peopleNbr}</Value>
                    </div>
                </div>
            </div>

            <Separator />

            <Title fontSize={isTabletOrMobile ? '18px' : '24px'} title="Informations personnelles" />
            <div className="row">
                <div>
                    <H3 style={{ fontSize: isTabletOrMobile ? '20px' : '26px' }}>{bookingData?.fullname}</H3>
                    <div className="row-small">
                        <Label>Email:</Label>
                        <Value>{bookingData?.email}</Value>
                    </div>
                    <div className="row-small">
                        <Label>Téléphone:</Label>
                        <Value>{bookingData?.phone}</Value>
                    </div>
                </div>
                <div>
                    {
                        bookingData?.organization !== "" ?
                            <div className="row-small">
                                <Label>Organisation:</Label>
                                <Value>{bookingData?.organization}</Value>
                            </div>
                        : 
                            null
                    }
                    {
                        bookingData?.job !== "" ?
                            <div className="row-small">
                                <Label>Fonction:</Label>
                                <Value>{bookingData?.job}</Value>
                            </div>
                        :
                            null
                    }
                </div>
            </div>

            <Separator />
  
            {
                bookingData?.addons?.length > 1 ?
                    <>
                        <Title fontSize={isTabletOrMobile ? '18px' : '24px'} title="Autres services à la demande" />
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: isTabletOrMobile ? 'wrap' : 'nowrap' }}>
                            <div className="checklist-column" style={{ minWidth: 400 }}>
                                {
                                    bookingData?.addons?.map((val: any, ind: number)=>{
                                        if(ind % 2 === 0){
                                            return(
                                                <div key={ind} className="row-small">
                                                    <img src={Checked} alt="checked" style={{ marginRight: 20 }} />
                                                    <Value>{val.name}</Value>
                                                </div>
                                            )
                                        }else{
                                            return null
                                        }
                                    })
                                }
                            </div>
                            <div className="checklist-column" style={{ minWidth: 400 }}>
                                {
                                    bookingData?.addons?.map((val: any, ind: number)=>{
                                        if(ind % 2 === 1){
                                            return(
                                                <div key={ind} className="row-small">
                                                    <img src={Checked} alt="checked" style={{ marginRight: 20 }} />
                                                    <Value>{val.name}</Value>
                                                </div>
                                            )
                                        }else{
                                            return null
                                        }
                                    })
                                }
                            </div>    
                        </div>
                        <Separator />
                    </>
                :
                    null
            }

            {
                bookingData?.message !== "" ?
                    <>
                        <Title fontSize={isTabletOrMobile ? '18px' : '24px'} title="remarque par rapport à votre réservation" />
                        <Value>
                            {bookingData?.message}
                        </Value>

                        <Separator />
                    </>
                :
                    null
            }

            <div style={{ height: 75, display: 'flex', justifyContent: isTabletOrMobile ? 'center' : 'flex-end' }}>
                <Button onClick={props.clickValid}>
                    {
                        validatingReservation ?
                            <div style={{  marginTop: 5 }}>
                                <RiseLoader color={"white"} loading={validatingReservation} size={20} />
                            </div>
                        :
                            "Valider ma demande"
                    }
                </Button>
            </div>
        </div>
    )

}

export default App