import React from "react"
import styled from "styled-components"
import ListItem from "../SallesList/ListItem";
import { useHistory, useLocation } from "react-router-dom"
import { getSalles, setBookingActiveStep, setSelectedSalle } from "../../store/actionCreators"
import { useDispatch, useSelector } from "react-redux";
import RiseLoader from "react-spinners/ClipLoader";
import { BASE_URL } from "../../utils/api";
import { useMediaQuery } from "react-responsive";
interface CustomizedState {
    id: string,
    title: string
}

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 1220px;
    margin: 0 auto;
`;

const Title = styled.h2`
    margin: 0 auto;
    // margin-top: 20px;
    font-size: 25px;
    font-weight: 500;
    color: #00b4ff;
    padding: 10px 130px;
    background: white
`;

const App = () => {

    const [title, setTitle] = React.useState('')
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation();
    const salles = useSelector((state: any) => state.salles)
    const sallesLoading = useSelector((state: any) => state.sallesLoading)
    const roomData = useSelector((state: any) => state.selectedSalle)

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

    React.useEffect(() => {
        
        const state = location.state as CustomizedState;
        console.log({location})
        console.log({window})
        console.log(window.location.href)
        const title = !!state?.title ? state?.title : '';
        const filters = {
            eventType: !!state?.id ? state?.id : ''
        }
        dispatch(getSalles(filters, null))
        
        setTitle(!!state?.id ? 'Salles de '+title : title)

    }, [history.location.pathname, location.state, dispatch])

    const handleSelect = (ind: number, item: Object) => {
        dispatch(setBookingActiveStep(1))
        dispatch(setSelectedSalle({...item, ...roomData}))
        setTimeout(() => {
            history.push('/booking')
        }, 100);
    }

  return (
        <>
            <Title style={{ padding: isTabletOrMobile ? '10px 20px' : '10px 130px' }}>
                {title}
            </Title>
            <Container style={{ justifyContent: isTabletOrMobile ? 'center' : '' }}>
                {
                    sallesLoading ?
                        <div style={{ minHeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <RiseLoader color={"#00B4FF"} loading={sallesLoading} size={30} />
                        </div>
                    :
                    salles.map((val, ind)=> {

                        const imageLink = BASE_URL+'/api/uploads/'+val.filePath
                        
                        return(
                            <div key={ind} onClick={()=>handleSelect(ind, val)} style={{ cursor: 'pointer' }}>
                                <ListItem 
                                    name={val.name ? val.name : ''}
                                    building={val.organismes[0]?.name ? val.organismes[0]?.name : ''}
                                    price={val.price ? val.price : ''}
                                    location={val.wilayas[0]?.name ? val.wilayas[0]?.name : ''}
                                    capacity={val.max_capacity ? val.max_capacity : ''}
                                    image={imageLink}
                                    clickSelect={()=>{}}
                                    selectedItem={{ id: '' }}
                                    // item={val.id}
                                />
                            </div>
                        )
                    })
                }
            </Container>
        </>
  )
}

export default App
