import React from "react"
import styled from "styled-components"
import Pin from "../../assets/small-pin.svg"
import People from "../../assets/people.svg"
import Placeholder from "../../assets/salleplaceholder.png"

interface ListItemProps {
    name?: string,
    building?: string,
    price?: string,
    location?: string,
    image?: string,
    clickSelect?: () => void,
    selectedItem?: {
        id?: string
    },
    item?: string,
    capacity?: string
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    width: 270px;
    // height: 340px;
    // background: white;
    margin: 15px 12px;
    overflow: hidden;
    position: relative;
    z-index: 0
`;

const InfoDiv = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background: white;
    padding: 15px;
    position: relative;
    z-index: 1;
    margin-top: -20px
`;

const PriceDive = styled.div`
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: #0080FF;
    padding: 2px 10px;
    position: relative;
    z-index: 1;
    margin-top: -30px;
    color: white;
    width: fit-content;
    p {
        font-size: 10px;
        margin-right: 5px
    };
    strong {
        font-size: 20px;
        font-weight: 500
    };
    span {
        font-size: 9px;
        margin-left: 5px;
        margin-top: -5px;
        font-weight: 500
    }
`;

const Location = styled.div`
    display: flex;
    position: relative;
    z-index: 1;
    align-items: center;
    margin-top: 10px;
    p {
        font-size: 13px;
        opacity: 0.7;
        color: '#102D47';
        font-weight: 300
    }
`;

// const Button = styled.button`
//     display: flex;
//     align-items: center;
//     margin-top: 10px;
//     border: none;
//     height: 50px;
//     border-radius: 10px;
//     cursor: pointer;
//     p {
//         width: 100%;
//         text-align: center
//     }
// `;


const ListItem = (props: ListItemProps) => {

    const id = !!props?.selectedItem ? props?.selectedItem?.id : ''

    return (
        <Wrapper style={{ border: props.item === id ? '5px solid #0080FF' : '5px solid transparent', transition: 'ease 0.3s' }}>
            {
                console.log('testing this ', props.image.split('uploads/')[1] === 'null')
            }
            {
                console.log('testing this ', props.image.split('uploads/'))
            }
            {
                console.log(props.image)
            }
            {
                !!props.image ?
                    <img 
                        src={
                            (props.image.split('uploads/')[1] === 'null' || props.image.split('uploads/')[1] === '') ? Placeholder : props.image 
                        } 
                        alt="pic" style={{ height: 190 }} 
                    />
                :
                    <div className="placeholder" />
            }
            {
                (props.image.split('uploads/')[1] === 'null' || props.image.split('uploads/')[1] === '') ?
                    null
                :
                    <div className="overlay" />
            }
            <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: 130, left: 20 }}>
                <img src={People} alt="people" style={{ marginRight: 5 }} />
                <span style={{ color: 'white', fontSize: 13 }}>{props.capacity}</span>
            </div>
            
            <InfoDiv>
                <PriceDive>
                    <p>À partir de </p><strong>{props.price}</strong> <span>DA</span>
                </PriceDive>
                <div>
                    <h6 style={{ color: '#102D47', opacity: 0.7, fontSize: 12, fontWeight: 400, marginTop: 10 }}>{props.building}</h6>
                    <h4 style={{ color: '#102D47', fontSize: 22, fontWeight: 500, }}>{props.name}</h4>
                </div>
                <Location>
                    <img src={Pin} alt="pin" style={{ marginRight: 10 }} />
                    <p>
                        {props.location}
                    </p>
                </Location>

                {/* <Button onClick={props.clickSelect} style={{ backgroundColor: props.index === props.selectedItem ? '#00B4FF' : '#F2F6FA', transition: 'ease 0.3s' }}>
                    <p style={{ color: props.index === props.selectedItem ? 'white' : '#0080FF', fontSize: 16, fontWeight: 300, transition: 'ease 0.3s' }}>
                        Sélectionner
                    </p>
                </Button> */}
            </InfoDiv>
        </Wrapper>
    )
}

export default ListItem