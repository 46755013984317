import React from 'react'
import styled from "styled-components"

interface InputProps {
    inputColor?: string,
    icon?: any,
    placeholder?: string,
    value?: string,
    click?: () => void,
    hideInput?: boolean,
    inputType?: string,
    textChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Input = styled.input`
    padding: 0.5em;
    margin: 0.5em;
    color: ${(props: InputProps) => props.inputColor || "#102D47"};
    border: none;
    border-radius: 3px;
    :focus {
        outline: none;
    };
    font-family: 'Poppins';
    font-size: 14px
`;

const Container = styled.div`
  padding: 15px;
  background: white;
  border: none;
  display: flex;
  align-items: center;
    border-radius: 15px
`;

const Icon = styled.img`
  width: 18px;
  height: 22px;
`;

export const FilterInput = (props: InputProps) => {
    return(
        <Container>
            <Icon onClick={props.click} src={props.icon} alt="icon" />
            {props.hideInput || <Input 
                type={props.inputType || 'text'} 
                inputColor="#102D47" 
                placeholder={props.placeholder}
                value={props.value}
                onClick={props.click}
                onChange={props.textChange}
            />}
        </Container> 
    )

}
