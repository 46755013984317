import React, { FunctionComponent } from "react"
import "./styles.css"
import Ribbon6 from "../../assets/ribbon6.svg"
import Title from "../Title"
import HowItWorksCard from "../HowItWorksCard"
import List from "../../assets/listicon.svg"
import Home from "../../assets/homeicon.svg"
import Check from "../../assets/check-circle.svg"
import Tip from "./Tip"
import { useMediaQuery } from "react-responsive"

const data = [
    {
        description: "Renseignez les informations relatives à votre projet d’évènement",
        icon: List
    },
    {
        description: "Sélectionnez un lieu",
        icon: Home
    },
    {
        description: "Confirmez votre demande de réservation",
        icon: Check
    },
]

const App: FunctionComponent = () => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

  return (
        <div className="howItWorksBloc" id="fonctionnement" style={{padding: isTabletOrMobile ? '50px 0' : ''}}>
            <div className="howItWorks"
                style={{
                    flexDirection: isTabletOrMobile ? 'column' : 'row'
                }}
            >
                <img src={Ribbon6} alt="ribbon" className="ribbon6" />

                <div 
                    className="titleBox" 
                    style={{ 
                        margin: isTabletOrMobile ? '0' : '', 
                        padding: isTabletOrMobile ? '0 20px' : '',
                        width: isTabletOrMobile ? '100%' : ''
                    }}
                >
                    <Title 
                        width="100%" 
                        fontSize={isTabletOrMobile ? '24px' : "43px" }
                        title="Comment ça marche ?" 
                        textAlign={isTabletOrMobile ? 'center' : "left" }
                        marginBottom="20px" 
                        marginTop={isTabletOrMobile ? '0' : "-50px"} 
                    />
                    <p className="subtitle" style={{ padding: isTabletOrMobile ? '0 20px' : '' }}>
                        Réservez votre salle rapidement en 3 étapes simples :
                    </p>
                </div>

                <div className="list-howitworks"
                    style={{
                        justifyContent: isTabletOrMobile ? 'end' : ''
                    }}
                >
                    {
                        data.map((val, ind)=>{
                            return(
                                <div key={ind}>
                                    <HowItWorksCard icon={val.icon} description={val.description} number={ind+1} />
                                </div>
                            )
                        })
                    }
                </div>
                
            </div>

            <Tip />
        </div>
  )
}

export default App