import moment from "moment";
import React, { createRef, forwardRef, useImperativeHandle } from "react";
import { useEffect, useState } from "react";
import styled from "styled-components"

interface TimePickerProprs {
    title?: string,
    clickCancel?: ()=> void,
    clickChoose?: ()=> void,
}

const Container = styled.div`
    width: 190px;
    height: 250px;
    position: absolute;
    background: white;
    z-index: 3;
    border-radius: 20px;
    box-shadow: 1px 1px 5px 2px #d3d3d340;
    margin-top: 47px;
`;

const Title = styled.p`
    font-size: 14px;
    opacity: 0.5;
    margin-bottom: 30px
`;

const TimeContainer = styled.div`
    text-align: center;
    height: 90px;
    width: 50px;
    border: 1px solid rgba(211, 211, 211, 0.22);
    border-radius: 10px;
    overflow: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; 
    scrollbar-width: none;
`;

const Text = styled.p`
    padding: 10px;
    border-bottom: 1px solid #d3d3d338;
    cursor: pointer
`;

const Button = styled.div`
    width: 70px;
    height: 40px;
    border-radius: 12px;
    border: none;
    background: #0080ff;
    color: white;
    font-family: 'Poppins';
    position: absolute;
    right: 22px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px
`

const hours = [
    "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12",
    "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"
]

const minutes = [
    "00", "30"
]

const ampm = [
    "AM", "PM"
]

const TimePicker = forwardRef((props: TimePickerProprs, ref) => {

    useImperativeHandle(ref, () => ({

        returnTime() {
            return selectedHour+':'+selectedMinutes
        }

    }));

    const arrLength = hours.length;
    const [elRefs, setElRefs] = React.useState([]);
  
    React.useEffect(() => {
      // add or remove refs
      setElRefs(elRefs => (
        Array(arrLength).fill(undefined).map((_, i) => elRefs[i] || createRef())
      ));
    }, [arrLength]);

    const [selectedHour, setSelectedHour] = useState((moment().hour()).toString())
    const [selectedMinutes, setSelectedMinutes] = useState('00')
    // const [selectedPeriod, setSelectedPeriod] = useState('AM')

    useEffect(() => {
        if(!!elRefs){
            if(!!elRefs[hours.indexOf(selectedHour)] && !!elRefs[hours.indexOf(selectedHour)].current){
                console.log('here')
                elRefs[hours.indexOf(selectedHour)].current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
            }
        }
    }, [elRefs])

    return (
        <Container>
            <div style={{ height: '90%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                <div>
                    <Title>
                        Heure
                    </Title>

                    <TimeContainer style={{ boxShadow: '1px 1px 15px 2px #d3d3d338', }}>
                        {
                            hours.map((val, ind)=>{
                                return(
                                    <Text 
                                        ref={elRefs[ind]}
                                        key={ind} id={"value"+ind} 
                                        style={selectedHour === val ? { background: '#0080ff', color: 'white' } : {}}
                                        onClick={()=>{
                                            setSelectedHour(val)
                                        }}
                                    >
                                        {val}
                                    </Text>
                                )
                            })
                        }
                    </TimeContainer>

                </div>
                <div>
                    <Title>
                        Minutes
                    </Title>
                    <TimeContainer style={{ overflow: 'hidden' }}>
                        {
                            minutes.map((val, ind)=>{
                                return(
                                    <Text 
                                        key={ind}
                                        style={selectedMinutes === val ? { background: '#0080ff', color: 'white' } : {}}
                                        onClick={()=>{
                                            setSelectedMinutes(val)
                                        }}
                                    >
                                        {val}
                                    </Text>
                                )
                            })
                        }
                    </TimeContainer>
                </div>
                {/* <div>
                    <Title>
                        Peroide
                    </Title>
                    <TimeContainer style={{ overflow: 'hidden' }}>
                        {
                            ampm.map((val, ind)=>{
                                return(
                                    <Text 
                                        key={ind}
                                        style={selectedPeriod === val ? { background: '#0080ff', color: 'white' } : {}}
                                        onClick={()=>{
                                            setSelectedPeriod(val)
                                        }}
                                    >
                                        {val}
                                    </Text>
                                )
                            })
                        }
                    </TimeContainer>
                </div> */}

            </div>

            <Button onClick={props.clickCancel} style={{ background: '#ebebeb', right: 100, color: 'grey' }}>
                Annuler
            </Button>
            <Button onClick={props.clickChoose}>
                Choisir
            </Button>

        </Container>
    )
})

export default TimePicker