import React from "react"
import styled from "styled-components"

interface TitleProprs {
    title?: string,
    fontSize?: string,
    width?: string,
    textAlign?: string,
    marginBottom?: string,
    marginTop?: string
}

const TitleH1 = styled.h1`
    color: #0080FF;
    font-size: ${(props: TitleProprs) =>  props.fontSize || '45px'};
    font-family: 'Poppins';
    font-weight: bold;
    text-align: ${(props: TitleProprs) =>  props.textAlign || 'center'};
    width: ${(props: TitleProprs) =>  props.width || '65%'};
    position: relative;
    // z-index: 1;
    margin-bottom: ${(props: TitleProprs) =>  props.marginBottom || '100px'};
    margin-top: ${(props: TitleProprs) =>  props.marginTop || '0px'};
`;

const Title = (props: TitleProprs) => {
    return (
        <TitleH1 
            fontSize={props.fontSize} 
            width={props.width} 
            textAlign={props.textAlign} 
            marginBottom={props.marginBottom} 
            marginTop={props.marginTop} 
        >
            {props.title}
        </TitleH1>
    )
}

export default Title