import React from "react"
import styled from "styled-components"
import { useMediaQuery } from 'react-responsive'
import ClipLoader from "react-spinners/ClipLoader";
interface ButtonProps {
    clickSearch?: () => void,
    loading?: boolean
}

const Button = styled.button`
    background: #00B4FF;
    color: white;
    font-size: 14px;
    border: 2px solid #00B4FF;
    border-radius: 15px;
    width: 245px;
    height: 70px;
    font-family: 'Poppins';
    margin-right: 10px;
    cursor: pointer;
`;

const SearchButton = (props: ButtonProps) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    return (
        <Button className="search-button" onClick={props.clickSearch} style={{ margin: isTabletOrMobile ? '20px 0' : '0 10px 0 0' }} >
            {
                props.loading ?
                    <div style={{  marginTop: 5 }}>
                        <ClipLoader color={"white"} loading={props.loading} size={20} />
                    </div>
                :
                    "Rechercher"                
            }
        </Button>
    )
}

export default SearchButton