import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import Title from "./Title"
import styled from "styled-components"
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Unchecked from "../../assets/inactive-checkbox.svg"
import Checked from "../../assets/check-active.svg"
import Select from 'react-select'
import ChevDown from "../../assets/chevrons-down-small.svg"
import Minus from "../../assets/minus-circle.svg"
import Plus from "../../assets/plus-circle.svg"
import { ErrorMessage } from '@hookform/error-message'
import { useDispatch, useSelector } from "react-redux"
import { setBookingFormData, setBookingFormValidate } from "../../store/actionCreators"
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from "../../utils/constants"
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import moment from "moment"
import TimePicker from './TimePicker';
import { useMediaQuery } from 'react-responsive';

interface FormProps {
    clickNext: () => void,
    isTabletOrMobile?: boolean
}

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// const timeReg = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;

const StyledRadio = withStyles({
    root: {
        color: '#102D47',
        opacity: 0.4,
      '&$checked': {
        color: '#0080FF',
        opacity: 1
      },
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props} />);

  const StyledFormControlLabel = withStyles({
    label: {
        color: '#102D47',
        fontFamily: 'Poppins',
        marginRight: 20,
        opacity: 0.4
    },

  })((props: FormControlLabelProps)=><FormControlLabel {...props}/>)

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px
`;

const Label = styled.p`
    font-size: 16px;
    color: #102D47;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const SubmitButton = styled.input`
    background: #00B4FF;
    color: white;
    font-size: 14px;
    border: 2px solid #00B4FF;
    border-radius: 15px;
    width: 245px;
    height: 70px;
    font-family: 'Poppins';
    margin-right: 10px;
    cursor: pointer;
`;

const TextInput = styled.input`
    padding: 0px 10px 0px 10px;
    margin-right: 1em;
    color: #102D47;
    :focus {
        outline: none;
    };
    font-family: 'Poppins';
    font-size: 14px;
    border: solid 1px rgba(16, 45, 71, 0.15);
    border-radius: 10px;
    height: 45px;
    width: 225px;
`;

const StyledTextarea = styled.textarea`
    padding: 0.5em 1rem;
    margin-right: 1em;
    color: #102D47;
    max-width: 500px;
    :focus {
        outline: none;
    };
    font-family: 'Poppins';
    font-size: 14px;
    border: solid 1px rgba(16, 45, 71, 0.15);
    border-radius: 10px;
`;
const customStyles = {
    control: (styles: any) => ({ ...styles, 
        fontFamily: 'Poppins',
        fontSize: 14,
        border: 'solid 1px rgba(16, 45, 71, 0.15)',
        borderRadius: 10,
        height: 45,
        backgroundColor: 'transparent',
    }),
    option: (styles: any, data: any) => {
        return {
        ...styles,
        backgroundColor: data.isSelected ? '#00B4FF' : 'white',
        ':active': {
            ...styles[':active'],
        },
        };
    },
    input: (styles: any) => ({ 
        
    }),
    placeholder: (styles: any) => ({ 
        opacity: 0.4
    }),
    indicatorSeparator: () => ({
        display: 'none'
    })
};


function App(props: FormProps) {

    const roomData = useSelector((state: any) => state.selectedSalle)
    const bookingData = useSelector((state: any) => state.bookingData)
    const locations = useSelector((state: any) => state.locations)
    const eventTypes = useSelector((state: any) => state.eventTypes)
    const services = useSelector((state: any) => state.services)
    // const formValidate = useSelector((state: any) => state.formValidate)
    const [date, onChange] = React.useState(!!roomData ? roomData.date : '');
    const [showDayPicker, setShowDayPicker] = React.useState(false);
    const [showTimePicker, setShowTimePicker] = React.useState(false);
    const [checkedAddons, setCheckedAddons] = React.useState<Object[]>(!!bookingData?.addons ? bookingData.addons : []);
    const [userIs, setUserIs] = React.useState(!!bookingData?.organization ? bookingData.organization : 'Entreprise');
    const [time, setTime] = React.useState(!!bookingData ? bookingData.time : moment().format('hh:mm'));
    const [fullname, setFullname] = React.useState(!!bookingData ? bookingData.fullname : '');
    const [phone, setPhone] = React.useState(!!bookingData ? bookingData.phone : '');
    const [email, setEmail] = React.useState(!!bookingData ? bookingData.email : '');
    const [message, setMessage] = React.useState(!!bookingData ? bookingData.message : '');
    const [duration, setDuration] = React.useState(!!roomData?.duration ? roomData.duration : 1)
    const [people, setPeople] = React.useState(!!roomData.people ? roomData.people : 1)
    const [selectedType, setSelectedType] = React.useState(Array.isArray(eventTypes) ? eventTypes?.find((c) => c.id === roomData.eventType) : undefined)
    const [selectedLocation, setSelectedLocation] = React.useState(!!roomData.location ? roomData.location : roomData.wilayas[0])
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const dispatch = useDispatch()
    const timePickerRef = useRef(null)

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

    const today = new Date();


    
    const onSubmit = (data: any) => {

        data.duration = duration
        data.peopleNbr = people
        data.addons = checkedAddons
        data.location = selectedLocation
        data.type = selectedType
        data.date = date
        data.time = time
        data.organization = userIs

        dispatch(setBookingFormData(data))
        dispatch(setBookingFormValidate(true))
        setTimeout(() => {
            props.clickNext()
        }, 300);
            
        // if(formValidate){
        //     dispatch(setBookingFormData(data))
        //     props.clickNext()
        // }else{
        //     dispatch(setBookingFormData(data))

        //     if(errors && Object.keys(errors).length === 0 && errors.constructor === Object){
        //         if(!!date && !!selectedType && !!selectedLocation && people > 0 && duration > 0){
        //             dispatch(setBookingFormValidate(true))
        //             props.clickNext()
        //         }
        //     }
        // }

    };

    const handleClickDay = (date: any, modifiers) => {
        if (modifiers.disabled) {
            return;
        }
        onChange(date)
        setShowDayPicker(false)
    }

    const clickCancel = () => {
        setShowTimePicker(false)
    }

    const clickChoose = () => {
        console.log('ok')
        console.log(moment().format('hh:mm'))
        console.log(timePickerRef.current.returnTime())
        setTime(timePickerRef.current.returnTime())
        setShowTimePicker(false)
    }

    const modifiers = {
        selected: date,
    };

    const DropdownIndicator = () => {
        return (
            <div className="arrow-down">
                <img src={ChevDown} alt="arrow" />
            </div>
        )
    }

    const DropdownIndicatorLocation = () => {
        return null
    }

    const clickOperator = (type: string, operator: string) => {
        if(type === "people"){
            if(operator === "plus"){
                if(people < roomData.max_capacity){
                    setPeople(people+1)
                }
            }else{
                if(people>0)
                setPeople(people-1)
            }
        }else{
            if(operator === "plus"){
                setDuration(duration+1)
            }else{
                if(duration>0)
                setDuration(duration-1)
            }
        }
    }
  
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Title fontSize={isTabletOrMobile ? '18px' : '24px'} title="Informations sur l’événement" />
            <div className="form-block" >
                <Label style={{ fontSize: isTabletOrMobile ? 14 : '' }}>Type d’événement</Label>
                <Select
                    value={selectedType}
                    className="big-input"
                    styles={customStyles} 
                    placeholder="Selectionnez"
                    options={eventTypes}
                    isDisabled={!!selectedType ? true : false}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.name}
                    components={{ DropdownIndicator }}
                    onChange={(selectedOption: any) => {
                        setSelectedType(selectedOption)
                    }}
                />
                {!!selectedType || <p className="error">Champ obligatoire</p>}
                <Row style={{ flexDirection: isTabletOrMobile ? 'column' : 'row' }}>
                    <div style={{ width: isTabletOrMobile ? '100%' : 300 }}>
                        <Label style={{ fontSize: isTabletOrMobile ? 14 : '' }}>Où ? </Label>
                        <Select
                            value={selectedLocation}
                            styles={customStyles} 
                            className="big-input"
                            placeholder="Ville, hôtel ou nom d’une salle"
                            options={locations}
                            isDisabled={true}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.name}
                            components={{ DropdownIndicator: DropdownIndicatorLocation }}
                            onChange={(selectedOption: any) => {
                                setSelectedLocation(selectedOption)
                            }}
                        />
                        {!!selectedLocation || <p className="error">Champ obligatoire</p>}
                    </div>
                    <div style={{ position: 'relative', width: isTabletOrMobile ? '100%' : '', }}>
                        <Label style={{ fontSize: isTabletOrMobile ? 14 : '' }}>Date</Label>
                        <TextInput 
                            value={!!date ? moment(date).format('DD/MM/YYYY') : ''} 
                            placeholder="Quand ?" 
                            name="date"
                            disabled={!!date ? true : false}
                            style={{ 
                                opacity: 0.5, 
                                width: isTabletOrMobile ? '-webkit-fill-available' : '',
                                marginRight: isTabletOrMobile ? 0 : ''
                            }}
                            onChange={()=>{}} 
                            onClick={()=>{setShowDayPicker(true)}}
                        />
                        {!!date || <p className="error">Champ obligatoire</p>}
                        {
                            showDayPicker &&
                            <DayPicker
                                locale="fr"
                                months={MONTHS}
                                weekdaysLong={WEEKDAYS_LONG}
                                weekdaysShort={WEEKDAYS_SHORT}
                                firstDayOfWeek={1}
                                onDayClick={(date, modifiers)=>handleClickDay(date, modifiers)}
                                modifiers={modifiers}
                                disabledDays={{ before: today }}
                            />
                        }
                    </div>
                    <div style={{ width: isTabletOrMobile ? '100%' : '' }}>
                        <Label style={{ fontSize: isTabletOrMobile ? 14 : '' }}>Nombre de personnes</Label>
                        <div style={{ position: 'relative', width: isTabletOrMobile ? '100%' : '' }}>
                            <p 
                                onClick={()=>{
                                    clickOperator("people", "plus")
                                    // (document.getElementById("peops") as HTMLInputElement).stepUp()
                                }} 
                                className="plus"
                                style={{
                                    right: isTabletOrMobile ? 15 : ''
                                }}
                                // disabled
                            >
                                    <img src={Plus} alt="plus" />
                            </p>
                            <TextInput 
                                style={{ 
                                    padding: '0 50px 0 50px', 
                                    width: isTabletOrMobile ? '-webkit-fill-available' : 100, 
                                    backgroundColor: 'transparent', 
                                    marginRight: isTabletOrMobile ? 0 : ''
                                }} 
                                value={people} 
                                min={0} 
                                id="peops"
                                max={roomData.max_capacity}
                                // disabled
                                type="number" 
                                placeholder="" 
                                // {...register("peopleNbr", {required: true, max: 100})} 
                                onChange={(event: any) => setPeople(parseInt(event.target.value))}
                            />
                            <p 
                                onClick={()=>{
                                    clickOperator("people", "minus")
                                    // (document.getElementById("peops") as HTMLInputElement).stepDown()
                                }} 
                                className="minus"
                                // disabled
                            >
                                    <img src={Minus} alt="minus" />
                            </p>
                        </div>
                        {(!!people && people > 0) || <p className="error">Champ obligatoire</p>}
                    </div>
                </Row>
                <Row>
                    <div style={{ width: isTabletOrMobile ? '100%' : '' }}>
                        <Label style={{ fontSize: isTabletOrMobile ? 14 : '' }}>Durée <small style={{ opacity: 0.5 }}>(heures)</small></Label>
                        <div style={{ position: 'relative' }}>
                            <p 
                                onClick={()=>{
                                    clickOperator("duration", "plus")
                                    // (document.getElementById("duration") as HTMLInputElement).stepUp()
                                }} 
                                className="plus"
                                style={{
                                    right: isTabletOrMobile ? 15 : ''
                                }}
                            >
                                    <img src={Plus} alt="plus" />
                            </p>
                            <TextInput 
                                style={{ 
                                    padding: '0 50px 0 50px', 
                                    width: isTabletOrMobile ? '-webkit-fill-available' : 100, 
                                    backgroundColor: 'transparent', 
                                    marginRight: isTabletOrMobile ? 0 : ''
                                }} 
                                min={1} 
                                value={duration} 
                                id="duration"
                                type="number" placeholder="" 
                                // {...register("duration", {required: true})} 
                                onChange={(event: any) => setDuration(parseInt(event.target.value))}
                            />
                            <p 
                                onClick={()=>{
                                    clickOperator("duration", "minus")
                                    // (document.getElementById("duration") as HTMLInputElement).stepDown()
                                }} 
                                className="minus"
                            >
                                    <img src={Minus} alt="minus" />
                            </p>
                        </div>
                        {(!!duration && duration > 0) || <p className="error">Champ obligatoire</p>}
                    </div>
                    <div style={{ position: 'relative', width: isTabletOrMobile ? '100%' : '' }}>
                        <Label style={{ fontSize: isTabletOrMobile ? 14 : '' }}>Heure</Label>

                        {
                            showTimePicker &&
                            <TimePicker 
                                ref={timePickerRef}
                                clickCancel={clickCancel}
                                clickChoose={clickChoose}
                            />
                        }

                        <TextInput 
                            value={time} 
                            placeholder="" 
                            // {...register("time", {required: true, pattern: timeReg})} 
                            onClick={()=>{setShowTimePicker(true)}}
                            style={{
                                width: isTabletOrMobile ? '-webkit-fill-available' : '',
                                marginRight: isTabletOrMobile ? 0 : ''
                            }}
                            // onChange={(event)=>{
                            //     setTime(event.target.value)
                            //     setValue('time', event.target.value, {shouldValidate: true})
                            //     setShowTimePicker(false)
                            // }}
                        />
                        <div 
                            onClick={()=>{setShowTimePicker(true)}} 
                            style={{ 
                                position: 'absolute', 
                                top: isTabletOrMobile ? 45 : 48, 
                                right: isTabletOrMobile ? 20 : 30 
                            }}
                        >
                            <img src={ChevDown} alt="arrow" />
                        </div>
                        {!!time || <p className="error">Champ obligatoire</p>}
                        {/* <ErrorMessage
                            errors={errors}
                            name="time"
                            render={({ message }) => <p className="error">Champ obligatoire</p>}
                        /> */}
                    </div>
                </Row>
            </div>
            
            <Title fontSize={isTabletOrMobile ? '18px' : '24px'} title="Informations personnelles" />
            <div className="form-block">
                <Row style={{ alignItems: 'center' }}>
                    <FormLabel component="legend" style={{ marginRight: 50, fontSize: 16, color: '#102D47', fontFamily: 'Poppins' }}>Vous êtes ?</FormLabel>
                    <RadioGroup row defaultValue={userIs} {...register("type", { required: false })}>
                        <StyledFormControlLabel 
                            onChange={()=>{
                                setUserIs('Entreprise')
                                setValue('type', 'Entreprise', {shouldValidate: true})
                            }} 
                            name="type" value="Entreprise" 
                            control={<StyledRadio />} 
                            label="Organisation/Entreprise" 
                        />
                        <StyledFormControlLabel 
                            onChange={()=>{
                                setUserIs('Particulier')
                                setValue('type', 'Particulier', {shouldValidate: true})
                            }}
                            name="type" value="Particulier"
                            control={<StyledRadio />} 
                            label="Particulier" 
                        />
                    </RadioGroup>
                    <ErrorMessage
                        errors={errors}
                        name="type"
                        render={({ message }) => <p className="error">Champ obligatoire</p>}
                    />
                </Row>
                <Row>
                    <div style={{ width: isTabletOrMobile ? '100%' : '', }}>
                        <Label style={{ fontSize: isTabletOrMobile ? 14 : '' }}>Prénom & Nom</Label>
                        <TextInput 
                            type="text" placeholder="" {...register("fullname", {required: true})} 
                            value={fullname}
                            style={{
                                width: isTabletOrMobile ? '-webkit-fill-available' : '',
                                marginRight: isTabletOrMobile ? 0 : '',
                            }}
                            onChange={(event)=>{
                                setFullname(event.target.value)
                                setValue('fullname', event.target.value, {shouldValidate: true} )
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="fullname"
                            render={({ message }) => <p className="error">Champ obligatoire</p>}
                        />
                    </div>
                    <div style={{ width: isTabletOrMobile ? '100%' : '', }}>
                        <Label style={{ fontSize: isTabletOrMobile ? 14 : '' }}>Email</Label>
                        <TextInput 
                            type="email" placeholder="" 
                            {...register("email", {required: true, pattern: emailRegex})} 
                            value={email}
                            style={{
                                width: isTabletOrMobile ? '-webkit-fill-available' : '',
                                marginRight: isTabletOrMobile ? 0 : '',
                            }}
                            onChange={(event)=>{
                                setEmail(event.target.value)
                                setValue('email', event.target.value, {shouldValidate: true} )
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="email"
                            render={({ message }) => {
                                return(
                                    <p className="error">
                                        { 
                                            errors.email.type === "required" ?
                                                'Champ obligatoire'
                                            :
                                                'Email invalide'    
                                        }
                                    </p>
                                )
                            }}
                        />
                    </div>
                    <div style={{ width: isTabletOrMobile ? '100%' : '', }}>
                        <Label style={{ fontSize: isTabletOrMobile ? 14 : '' }}>Téléphone</Label>
                        <TextInput 
                            type="tel" 
                            placeholder="" 
                            {...register("phone", {required: true, pattern: /^(00213|\+213|0)(5|6|7)[0-9]{8}$/})} 
                            value={phone}
                            style={{
                                width: isTabletOrMobile ? '-webkit-fill-available' : '',
                                marginRight: isTabletOrMobile ? 0 : '',
                            }}
                            onChange={(event)=>{
                                setPhone(event.target.value)
                                setValue('phone', event.target.value, {shouldValidate: true} )
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="phone"
                            render={({ message }) => {
                                return(
                                    <p className="error">
                                        { 
                                            errors.phone.type === "required" ?
                                                'Champ obligatoire'
                                            :
                                                'Numéro de téléphone invalide'    
                                        }
                                    </p>
                                )
                            }}
                        />
                    </div>
                </Row>
                {
                    userIs === 'Entreprise' &&
                    <Row>
                        <div style={{ width: isTabletOrMobile ? '100%' : '', }}>
                            <Label style={{ fontSize: isTabletOrMobile ? 14 : '' }}>Nom de l’organisation/entreprise</Label>
                            <TextInput 
                                className="big-input" type="text" 
                                placeholder="" 
                                {...register("organizationName", {})} 
                                style={{
                                    width: isTabletOrMobile ? '-webkit-fill-available' : '',
                                    marginRight: isTabletOrMobile ? 0 : '',
                                }}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="organizationName"
                                render={({ message }) => <p className="error">Champ obligatoire</p>}
                            />
                        </div>
                        <div style={{ width: isTabletOrMobile ? '100%' : '', }}>
                            <Label style={{ fontSize: isTabletOrMobile ? 14 : '' }}>Fonction</Label>
                            <TextInput 
                                type="text" 
                                placeholder="" 
                                {...register("job", {})} 
                                style={{
                                    width: isTabletOrMobile ? '-webkit-fill-available' : '',
                                    marginRight: isTabletOrMobile ? 0 : '',
                                }}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="job"
                                render={({ message }) => <p className="error">Champ obligatoire</p>}
                            />
                        </div>
                    </Row>
                }
            </div>

            <Title fontSize={isTabletOrMobile ? '18px' : '24px'} title="Autres services à la demande " />
            <div className="form-block">
                <Row style={{ justifyContent: 'flex-start', alignItems: 'self-end' }}>
                    <div className="checklist-column" style={{ minWidth: 400 }}>
                        {
                            services.map((val, ind)=>{
                                if(ind % 2 === 0){
                                    return(
                                        <FormControlLabel
                                            key={ind}
                                            control={
                                                <Checkbox 
                                                    icon={<img src={Unchecked} alt="unchecked" />} 
                                                    checkedIcon={<img src={Checked} alt='checked' />}
                                                    {...register(val.name, {})}
                                                    checked={
                                                        checkedAddons.some((e: any) => e.id === val.id) ?
                                                            true 
                                                        :
                                                            bookingData?.addons?.some((e: any) => e.id === val.id)
                                                    }
                                                    onChange={(event)=>{
                                                        if (checkedAddons.some((e: any) => e.id === val.id)) {
                                                            setCheckedAddons(checkedAddons.filter((item: any) => item.id !== val.id))
                                                        }else{
                                                            setCheckedAddons([...checkedAddons, val])
                                                        }
                                                    }}
                                                />
                                            }
                                            label={val.name}
                                        />
                                    )
                                }else{
                                    return null
                                }
                            })
                        }
                    </div>
                    <div className="checklist-column" style={{ minWidth: 400 }}>
                        {
                            services.map((val, ind)=>{
                                if(ind % 2 === 1){
                                    return(
                                        <FormControlLabel
                                            key={ind}
                                            control={
                                                <Checkbox 
                                                    icon={<img src={Unchecked} alt="unchecked" />} 
                                                    checkedIcon={<img src={Checked} alt='checked' />}
                                                    {...register(val.name, {})}
                                                    checked={
                                                        checkedAddons.some((e: any) => e.id === val.id) ?
                                                            true 
                                                        :
                                                        bookingData?.addons?.some((e: any) => e.id === val.id)
                                                    }
                                                    onChange={()=>{
                                                        if (checkedAddons.some((e: any) => e.id === val.id)) {
                                                            setCheckedAddons(checkedAddons.filter((item: any) => item.id !== val.id))
                                                        }else{
                                                            setCheckedAddons([...checkedAddons, val])
                                                        }
                                                    }}
                                                />
                                            }
                                            label={val.name}
                                        />
                                    )
                                }else{
                                    return null
                                }
                            })
                        }
                    </div>
                </Row>
            </div>

            <Title fontSize={isTabletOrMobile ? '18px' : '24px'} title="Voulez-vous ajouter une remarque par rapport à votre réservation ?" />
            <div className="form-block" style={{ marginBottom: 15 }}>
                <Label style={{ fontSize: isTabletOrMobile ? 14 : '' }}>Message</Label>
                <StyledTextarea 
                    rows={4} cols={props.isTabletOrMobile ? 45 : 250} 
                    {...register("message", {})} 
                    value={message}
                    style={{
                        width: isTabletOrMobile ? '-webkit-fill-available' : '',
                        marginRight: isTabletOrMobile ? 0 : '',
                    }}
                    onChange={(event)=>{
                        setMessage(event.target.value)
                        setValue('message', event.target.value, {shouldValidate: true} )
                    }}
                />
            </div>

            <SubmitButton 
                id="submitForm" type="submit" value="Valider ma demande" 
                style={{
                    width: isTabletOrMobile ? '100%' : ''
                }}
            />
        </form>
    );
}

export default App