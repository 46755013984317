import React from "react";
import "./styles.css";
import logo from "../../assets/Logo@2x.png";
import logo2 from "../../assets/logo2.svg";
import Ribbon8 from "../../assets/ribbon8.svg";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const App: React.FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <ScrollAnimation animateIn="fadeIn" duration={0.3}>
      <div className="footer">
        <div
          className="footer-box"
          style={{ padding: isTabletOrMobile ? "30px 20px" : "" }}
        >
          <Link to="/">
            <img src={logo} alt="logo" className="logo" />
          </Link>
        </div>
        <img src={Ribbon8} alt="ribbon8" className="ribbon8" />
        <div
          className="footer-copyright"
          style={{ padding: isTabletOrMobile ? "30px 20px" : "" }}
        >
          <p>© izivent 2024</p>
          {/* <img src={logo2} alt="logo2" className="logo2" /> */}
        </div>
      </div>
    </ScrollAnimation>
  );
};

export default App;
