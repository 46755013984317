import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from "styled-components"
import { sendMessage } from '../../store/actionCreators';
import Lottie from "lottie-react";
import success from "../../assets/success.json";
import {
    GoogleReCaptcha
  } from 'react-google-recaptcha-v3';
import { useState } from 'react';
import { GoogleRecaptchaCheck } from './GoogleRecaptchaCheck';
import { useMediaQuery } from 'react-responsive';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 80px;
    background: white;
    border-radius: 30px;
    position: relative
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
`;

const Button = styled.button`
    background: #00B4FF;
    color: white;
    font-size: 14px;
    border: none;
    border-radius: 10px;
    width: 245px;
    height: 70px;
    font-family: 'Poppins';
    margin-right: 7px;
    display: block;
    align-self: flex-end;
    margin-top: 10px;
`;

const Label = styled.p`
    font-size: 16px;
    color: #102D47;
    padding: 0 0.5rem;
`;

const TextInput = styled.input`
    padding: 3px 10px 3px 10px;
    margin: 0.5em;
    color: #102D47;
    border: none;
    border-radius: 3px;
    :focus {
        outline: none;
    };
    font-family: 'Poppins';
    font-size: 14px;
    border: solid 1px rgba(16, 45, 71, 0.15);
    border-radius: 10px;
    height: 45px;
    width: 225px;
`;

const StyledTextarea = styled.textarea`
    padding: 0.5em 1rem;
    margin: 0.5em;
    color: #102D47;
    width: 91%;
    :focus {
        outline: none;
    };
    font-family: 'Poppins';
    font-size: 14px;
    border: solid 1px rgba(16, 45, 71, 0.15);
    border-radius: 10px;
`;

const ContactForm = () => {

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [token, setToken] = useState("");
    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch()
    const loading = useSelector((state: any) => state.sendingMsg)
    const status = useSelector((state: any) => state.sendingMsgStatus)

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

    console.log({token})

    const onSubmit = (data: any) => {
        console.log({data})
        dispatch(sendMessage(data.name, data.email, data.message))
    }

    return(
        <Container
            style={{ 
                width: isTabletOrMobile ? '100%' : '',
                padding: isTabletOrMobile ? '50px 20px' : '',
                boxSizing: 'border-box'
            }}
        >
            
            <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column' }}>
                <Row>
                    <div style={{ position: 'relative' }}>
                        <Label>Prénom & Nom</Label>
                        <TextInput 
                            type="text" 
                            placeholder="" 
                            {...register("name", {required: true})} 
                            style={{ width: isTabletOrMobile ? 245 : 225 }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => {
                                return(
                                    <p className="error" style={{ bottom: -10, left: 10 }}>
                                        Champ obligatoire
                                    </p>
                                )
                            }}
                        />
                    </div>
                    <div style={{ position: 'relative' }}>
                        <Label>Email</Label>
                        <TextInput 
                            type="email" 
                            placeholder="" 
                            {...register("email", {required: true, pattern: emailRegex})} 
                            style={{ width: isTabletOrMobile ? 245 : 225 }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="email"
                            render={({ message }) => {
                                return(
                                    <p className="error" style={{ bottom: -10, left: 10 }}>
                                        { 
                                            errors.email.type === "required" ?
                                                'Champ obligatoire'
                                            :
                                                'Email invalide'    
                                        }
                                    </p>
                                )
                            }}
                        />
                    </div>
                </Row>

                <div style={{ position: 'relative', marginTop: 20 }}>
                    <Label>Message</Label>
                    <StyledTextarea 
                        rows={4} 
                        cols={10} 
                        {...register("message", {required: true})} 
                        style={{ width: isTabletOrMobile ? '83%' : '91%' }}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="message"
                        render={({ message }) => {
                            return(
                                <p className="error" style={{ bottom: -10, left: 10 }}>
                                    Champ obligatoire
                                </p>
                            )
                        }}
                    />
                </div>

                {/* <GoogleRecaptchaCheck /> */}

                <Button
                    style={{ 
                        alignSelf: isTabletOrMobile ? 'center' : '',
                        margin: isTabletOrMobile ? '20px 0 0 0' : ''  
                    }}
                >
                    {
                        loading ?
                            <div style={{  marginTop: 5 }}>
                                <ClipLoader color={"white"} loading={loading} size={20} />
                            </div>
                        :
                            "Envoyer un message"
                    }
                </Button>
            </form>
            {
                status === "SUCCESS" &&
                <div style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgb(0 0 0 / 8%)', borderRadius: 30 }}>
                    <Lottie animationData={success} style={{ width: 50, height: 50, borderRadius: 30, overflow: 'hidden' }} />
                </div>
            }
        </Container> 
    )

}

export default ContactForm
