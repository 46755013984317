import React, { FunctionComponent, useEffect } from "react"
import "./styles.css"
import FilterBloc from "../../components/FilterBloc"
import SallesBloc from "../../components/SallesBloc"
import WhyIziventBloc from "../../components/WhyIziventBloc"
import HowItWorksBloc from "../../components/HowItWorksBloc"
import ReferencesBloc from "../../components/ReferencesBloc"
import ContactBloc from "../../components/ContactBloc"
import Footer from "../../components/Footer"
import { clearState, getLocatioins, getEventTypes, getServices, getSalles } from "../../store/actionCreators"
import { useDispatch } from "react-redux"

const App: FunctionComponent = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearState())
    dispatch(getLocatioins())
    dispatch(getEventTypes())
    dispatch(getServices())
    dispatch(getSalles({}, null))
  }, [dispatch])

  return (
    <div>
        <FilterBloc />
        <SallesBloc />
        <WhyIziventBloc />
        <HowItWorksBloc />
        <ReferencesBloc />
        <ContactBloc />
        <Footer />
    </div>
  )
}

export default App
