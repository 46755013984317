import React from "react";
import { HashLink } from 'react-router-hash-link';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import logo from '../../assets/Logo@2x.png';
import List from '@material-ui/core/List';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import useWindowDimensions from "../../utils/useWindowDimensions";

const links = [
    {title: "Salles", link: "#salles"},
    {title: "Avantages", link: "#avantages"},
    {title: "Fonctionnement", link: "#fonctionnement"},
    {title: "Références", link: "#references"},
    {title: "Contact", link: "#contact"},
]

function MobileNav() {

    const [open, setOpen] = React.useState(false);
    const { height, width } = useWindowDimensions();

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
      ) => {
        if (
          event &&
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }
    
        setOpen(open);
      };

    return (
        <div className="header">
            <img src={logo} alt="logo" className="logo" style={{ width: 100, height: 25 }} />
            <MenuOutlinedIcon 
                style={{ 
                    color: "white", fontSize: 40, 
                    position: 'absolute', right: 0, top: 15, 
                    cursor: 'pointer' 
                }} 
                onClick={toggleDrawer(true)} 
            />
            
            <SwipeableDrawer
                anchor={'top'}
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                
            >
            
                <List
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: width
                    }}
                >
                    {links.map(({ title, link }) => {
                        return(
                            <HashLink
                                onClick={toggleDrawer(false)}
                                className={"Mui-selected"}
                                to={link}
                                key={link}
                                smooth
                            >
                                <li>
                                    {title}
                                </li>
                            </HashLink>
                        )    
                    })}
                </List>

            </SwipeableDrawer>
        </div>
    )
}

export default MobileNav;