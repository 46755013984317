import React from 'react'
import styled from "styled-components"

interface ContactInfoProprs {
    icon?: string,
    text?: string
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    img {
        margin-top: 4px
    }
`;

const Text = styled.p`
  padding: 0 15px 15px 15px;
  font-size: 16px;
  color: rgba(16, 45, 71, 0.7);
  font-weight: 500;
`;

const ContactInfo = (props: ContactInfoProprs) => {
    return(
        <Container>
            <img src={props.icon} alt="contact" />
            <Text>
                {props.text}
            </Text>
        </Container> 
    )

}

export default ContactInfo
