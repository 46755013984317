import React from "react"
import styled from "styled-components"

interface TitleProprs {
    title?: string,
    fontSize?: string
}

const TitleH1 = styled.h1`
    color: #0080FF;
    font-size: ${(props: TitleProprs) =>  props.fontSize || '24px'};
    font-family: 'Poppins';
    font-weight: 400;
    text-align: left;
    position: relative;
    z-index: 1;
    line-height: 34px;
    margin: 15px 0
`;

const Title = (props: TitleProprs) => {
    return (
        <TitleH1 fontSize={props.fontSize}>
            {props.title}
        </TitleH1>
    )
}

export default Title