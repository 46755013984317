import React from "react"
import "./styles.css"
import logo from '../../assets/light-logo.svg';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const App: React.FC = () => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })
  
  return (
    <div className="booking-header">
        <Link to="/">
          <img src={logo} alt="logo" style={{ width: isTabletOrMobile ? 100 : 'auto' }} />
        </Link>
    </div>
  )
}

export default App
