import React, { FunctionComponent } from "react"
import "./styles.css"
import Ribbon3 from "../../assets/ribbon3.svg"
import Title from "../Title"
import WhyIziCard from "../WhyIziCard"
import Icon1 from "../../assets/icon1.svg"
import Icon2 from "../../assets/icon2.svg"
import Icon3 from "../../assets/icon3.svg"
import Icon4 from "../../assets/icon4.svg"
import { useMediaQuery } from "react-responsive"

const data = [
    {
        title: "Simplifiez-vous les choses",
        description: "En quelques clics, choisissez et réservez le lieu qui vous convient parmi un large éventail d’espaces disponibles.",
        icon: Icon1
    },
    {
        title: "Gagnez du temps",
        description: "Plus besoin d’appeler ou de vous déplacer pour trouver le lieu qu’il vous faut.",
        icon: Icon2
    },
    {
        title: "Faites des économies",
        description: "Notre solution offre les meilleurs prix disponibles sur le marché.",
        icon: Icon3
    },
    {
        title: "Soyez tranquilles",
        description: "Nos réservations sont sécurisées et offrent plusieurs avantages.",
        icon: Icon4
    },
]

const App: FunctionComponent = () => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

  return (
    <div 
        className="whyIziventBloc" id="avantages" 
        style={{
            padding: isTabletOrMobile ? '50px 0' : '',
        }}
    >
        <img src={Ribbon3} alt="ribbon" className="ribbon5" />

        <Title 
            fontSize={isTabletOrMobile ? '24px' : "43px"} 
            title="Pourquoi réserver votre salle sur Izivent ?" 
            width={isTabletOrMobile ? '100%' : ""}
            marginBottom={isTabletOrMobile ? '50px' : ""}
        />

        <div className="list-whyizivent">
            {
                data.map((val, ind)=>{
                    return(
                        <WhyIziCard 
                            key={ind} index={ind} 
                            icon={val.icon} title={val.title} 
                            description={val.description} 
                        />
                    )
                })
            }
        </div>
        
    </div>
  )
}

export default App