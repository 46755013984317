import React, { FunctionComponent } from "react";
import "./styles.css";
import Title from "../Title";
import ContactInfo from "./ContactInfo";
import MapPin from "../../assets/map-pin.svg";
import Phone from "../../assets/phone-call.svg";
import Mail from "../../assets/mail.svg";
import ContactForm from "./ContactForm";
import Ribbon7 from "../../assets/ribbon7.svg";
import { useMediaQuery } from "react-responsive";

const data = [
  {
    icon: MapPin,
    text: "29, Rue Hadj Ahmed Mohamed, Hydra, 16035, Algiers, Algeria. ",
  },
  {
    icon: Phone,
    text: " +213 770 940 911 ",
  },
  {
    icon: Mail,
    text: "contact@izivent.com",
  },
];

const App: FunctionComponent = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <div
      className="contactBloc"
      id="contact"
      style={{
        padding: isTabletOrMobile ? "50px 0" : "",
        flexDirection: isTabletOrMobile ? "column" : "row",
      }}
    >
      <img src={Ribbon7} alt="ribbon7" className="ribbon7" />
      <div
        className="titleBox"
        style={{
          width: isTabletOrMobile ? "90%" : "",
          margin: isTabletOrMobile ? "0 20px" : "",
          zIndex: 2,
        }}
      >
        <Title
          width="100%"
          fontSize={isTabletOrMobile ? "24px" : "40px"}
          title="Contactez-nous"
          textAlign={isTabletOrMobile ? "center" : "left"}
          marginBottom="15px"
          marginTop={isTabletOrMobile ? "20px" : "60px"}
        />
        <p
          className="subtitle"
          style={{ textAlign: isTabletOrMobile ? "center" : "left" }}
        >
          Restons plus proches
        </p>

        <div
          className="contact-infos"
          style={{ marginBottom: isTabletOrMobile ? 30 : 0 }}
        >
          {data.map((val, ind) => {
            return <ContactInfo key={ind} icon={val.icon} text={val.text} />;
          })}
        </div>
      </div>

      <ContactForm />
    </div>
  );
};

export default App;
