import "./styles.css"
import SwiperCom from "./SwiperCom"

const App = () => {

    return(
        <SwiperCom />
    )
}

export default App