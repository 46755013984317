import * as actionTypes from "./actionTypes"

const initialState: any = {
    selectedSalle: null,
    bookingData: {},
    bookingActiveStep: 0,
    formValidate: false,
    locations: [],
    eventTypes: [],
    salles: [],
    sallesLoading: false,
    sallesStatus: false,
    reservation: [],
    validatingReservation: false,
    reservationStatus: false,
    services: [],
    sendingMsg: false,
    sendingMsgStatus: "",
}

const reducer = (
  state: any = initialState,
  action: any
): any => {
  switch (action.type) {
    case actionTypes.SELECTED_SALLE:
        return {
            ...state,
            selectedSalle: action.selectedSalle
        }
    case actionTypes.SET_BOOKING_FORM_DATA:
        return {
            ...state,
            bookingData: action.bookingData
        }
    case actionTypes.BOOKING_ACTIVE_STEP:
        return {
            ...state,
            bookingActiveStep: action.bookingActiveStep
        }
    case actionTypes.BOOKING_FORM_VALIDATE:
          return {
              ...state,
              formValidate: action.formValidate
          }
    case actionTypes.GET_LOCATIONS:
        return {
            ...state,
            locations: action.locations
        }
    case actionTypes.GET_EVENT_TYPES:
        return {
            ...state,
            eventTypes: action.eventTypes
        }
    case actionTypes.GET_SERVICES:
        return {
            ...state,
            services: action.services
        }
    case actionTypes.GET_SALLES:
        return {
            ...state,
            salles: action.salles,
            sallesLoading: action.sallesLoading,
            sallesStatus: action.sallesStatus,
        }
    case actionTypes.RESERVE_SALLE:
        return {
            ...state,
            reservation: action.reservation,
            validatingReservation: action.validatingReservation,
            reservationStatus: action.reservationStatus
        }
    case actionTypes.CONTACT_:
        return {
            ...state,
            sendingMsg: action.sendingMsg,
            sendingMsgStatus: action.sendingMsgStatus,
        }
  }
  return state
}

export default reducer