import React from "react"
import "./styles.css"
import styled from "styled-components"
import Checked from "../../assets/check-circle-big.svg"
import { Link } from "react-router-dom"
import { useMediaQuery } from "react-responsive"

const H1 = styled.h1`
    color: #0080FF;
    font-size: 62px;
    font-family: 'Poppins';
    font-weight: bold;
    line-height: 34px;
    margin: 30px 0;
`;

const H3 = styled.h3`
    color: #0080FF;
    font-size: 26px;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 34px;
    margin: 10px 0;
`;

const P = styled.p`
    color: #102D47;
    font-size: 16px;
    font-family: 'Poppins';
    text-align: left;
    position: relative;
    z-index: 1;
    line-height: 34px
`;

const Button = styled.button`
    background: #00B4FF;
    color: white;
    font-size: 14px;
    border: none;
    border-radius: 15px;
    width: 245px;
    height: 70px;
    font-family: 'Poppins';
    cursor: pointer;
    margin-top: 50px
`;

const App = () => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

    return (
        <div className="done" style={{ padding: isTabletOrMobile ? '30px 20px' : '30px 60px' }} >
            <img src={Checked} alt="checked" />
            <H1 style={{ fontSize: isTabletOrMobile ? '35px' : '60px' }}>
                Merci...
            </H1>
            <H3 style={{ fontSize: isTabletOrMobile ? '20px' : '26px', textAlign: isTabletOrMobile ? 'center' : 'left' }}>
                Votre demande est prise en charge !
            </H3>
            <P style={{ textAlign: isTabletOrMobile ? 'center' : 'left' }}>
                Nous vous ferons un retour dans les plus brefs délais
            </P>

            <Link to={"/"}>
                <Button>
                    Retour vers l’accueil
                </Button>
            </Link>
        </div>
    )

}

export default App