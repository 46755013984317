import React from "react"
import { Provider } from "react-redux"
import { BrowserRouter as Router, Route, Switch  } from 'react-router-dom'
import Home from "./containers/Home"
import Booking from "./containers/Booking"
import Rooms from "./containers/Rooms"
import {store} from './store'
import {persistor} from './store'
import "./styles.css"
import { PersistGate } from 'redux-persist/integration/react'
import ScrollToTop from "./utils/ScrollToTop"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { REACT_APP_SITE_KEY } from "./utils/constants"

export default class App extends React.Component {
  render() {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={REACT_APP_SITE_KEY}
        language="fr"
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'body', // optional, default to "head", can be "head" or "body",
          nonce: undefined // optional, default undefined
        }}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router >   
                <ScrollToTop />
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route path="/booking">
                    <Booking />
                  </Route>
                  <Route path="/salles/:type">
                    <Rooms />
                  </Route>
                </Switch>
            </Router>
          </PersistGate>
        </Provider>
      </GoogleReCaptchaProvider>
    );
  }
}