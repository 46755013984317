import React from "react"
import "./styles.css"
import logo from '../../assets/Logo@2x.png';
import { HashLink } from 'react-router-hash-link';
import { useMediaQuery } from 'react-responsive'
import MobileNav from "./MobileNav"
import { Link } from "react-router-dom";

const App: React.FC = () => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    if(isTabletOrMobile){
        return (
            <MobileNav />
        )
    }else{
        return (
            <div className="header">
                <Link to="/">
                    <img src={logo} alt="logo" className="logo" />
                </Link>
                <nav>
                <ul>
                    <li>
                        <HashLink smooth to="#salles">
                            Salles
                        </HashLink>
                    </li>
                    <li>
                        <HashLink smooth to="#avantages">
                            Avantages
                        </HashLink>
                    </li>
                    <li>
                        <HashLink smooth to="#fonctionnement">
                            Fonctionnement
                        </HashLink>
                    </li>
                    <li>
                        <HashLink smooth to="#references">
                            Références
                        </HashLink>
                    </li>
                    <li>
                        <HashLink smooth to="#contact">
                            Contact
                        </HashLink>
                    </li>
                    
                    {/* <Link to="/booking" className="booking-button">
                        <li className="booking">
                            Réservez maintenant
                        </li>
                    </Link> */}
                    
                </ul>
                </nav>
            </div>
        )       
    }
}

export default App
