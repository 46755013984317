import React from "react"
import { useMediaQuery } from "react-responsive";
import styled from "styled-components"
import Info from "../../assets/info.svg"

interface TitleProprs {
    description?: string,
    icon?: string
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: auto;
    background: white;
    position: relative;
    z-index: 1;
    padding: 10px 25px;
    border-radius: 15px;
    margin: 0 auto;
    margin-top: 70px
`;

const Tip = (props: TitleProprs) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' })

    return (
        <Wrapper style={{ margin: isTabletOrMobile ? '0 20px' : '', marginTop: isTabletOrMobile ? '50px' : '' }}>
           <img src={Info} alt="info" className="info-icon" />
           <p className="tip-text">
            Votre requête est immédiatement prise en charge par notre équipe qualifiée qui vous prendra en charge et vous accompagnera tout le long du processus.
           </p>
        </Wrapper>
    )
}

export default Tip