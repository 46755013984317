import React, { FunctionComponent } from "react"
import "./styles.css"
import Header from '../../components/Header'
import Filters from '../../components/Filters'
import ScrollAnimation from 'react-animate-on-scroll'
import styled from "styled-components"
import TextTransition, { presets } from "react-text-transition"
import BackgroundSlider from 'react-background-slider'
import image3 from '../../assets/Conference-min.jpeg'
import image2 from '../../assets/Formation-min.jpeg'
import image1 from '../../assets/Reunion-min.jpeg'
import { useMediaQuery } from 'react-responsive'

const TitleH1 = styled.h1`
    color: white;
    font-size: 58px;
    font-weight: bold;
    width: 100%;
    position: relative;
    // margin-bottom: 100px;
    // margin-top: 200px
`;

const words = ["réunion", "formation", "conférence"];

const App: FunctionComponent = () => {

  const [wordIndex, setWordIndex] = React.useState(0);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setWordIndex(wordIndex => wordIndex + 1),
      4000 
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="filterBloc" style={{ background: '#00B4FF' }}>    
      <div className="filterBlocOverlay" />
      <BackgroundSlider
        images={[image1, image2, image3]}
        duration={3} transition={1} 
      />    
        <main style={isTabletOrMobile ? { padding: '0 10px' } : {}}>
            <Header />
            <ScrollAnimation animateIn="fadeIn">

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 80,
                  marginTop: isTabletOrMobile ? 100 : 200,
                }}
              >
                <TitleH1 style={{ width: 'fit-content', marginRight: 40, fontSize: isTabletOrMobile ? 24 : 50 }}>
                  Votre prochaine 
                </TitleH1>
                <TitleH1 style={{ 
                    width: 350, 
                    fontSize: isTabletOrMobile ? 24 : 50, 
                    marginTop: isTabletOrMobile ? 35 : 0,
                    marginLeft: isTabletOrMobile ? -15 : 0,
                  }}>
                    <TextTransition
                      text={ words[wordIndex % words.length] }
                      springConfig={ presets.gentle }
                      className="underline"
                    />
                </TitleH1>
              </div>
              <TitleH1 style={{marginBottom: isTabletOrMobile ? 50 : 100, fontSize: isTabletOrMobile ? 24 : 50}}>
                commence ici
              </TitleH1>
                
            </ScrollAnimation>
            <ScrollAnimation animateIn="slideInLeft" >
                <Filters />
            </ScrollAnimation>
        </main>
        
    </div>
  )
}

export default App
