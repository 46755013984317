import React from "react"
import styled from "styled-components"

const Sep = styled.div`
    border-top: dashed 1px #D3D3D3;
    margin: 20px 0;
`;

const Separator = () => {
    return (
        <Sep>
        </Sep>
    )
}

export default Separator